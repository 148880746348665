export const getSalaryRange = (salary) => {
  if(!salary) return "";
  if (salary <= 5000) return "nil";
  if (salary >= 5001 && salary <= 50000) return "0-50000";
  if (salary >= 50001 && salary <= 75000) return "50000-75000";
  if (salary >= 75001 && salary <= 100000) return "75000-100000";
  if (salary >= 100001 && salary <= 150000) return "100000-150000";
  if (salary >= 150001 && salary <= 200000) return "150000-200000";
  if (salary >= 200001 && salary <= 250000) return "200000-250000";
  if (salary > 250000) return "250000-";

  return "invalid";
};

export const SALARY_RANGE = {
  nil: "Up to $5,000",
  "0-50000": "$5,001 - $50,000",
  "50000-75000": "$50,001 - $75,000",
  "75000-100000": "$75,001 - $100,000",
  "100000-150000": "$100,001 - $150,000",
  "150000-200000": "$150,001 - $200,000",
  "200000-250000": "$200,001 to $250,000",
  "250000-": "Over $250,000",
};
