import React from "react";
import { UserContext, UserProvider } from "./contexts/UserContext";
import Navigation from "./navigation";
import { getCookie } from "./utils/cookies";
import TagManager from "react-gtm-module";
import { gapi } from "gapi-script";
// import logo from './images/Blingo.png'; // with import

const appSettings = window.appSettings;

const tagManagerArgs = {
  gtmId: appSettings.gtmContainer_id,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  React.useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div>
      {/* Preload tailwind classes */}
      <div className="bg-cream hidden" />
      <UserProvider>
        <CheckUserHOC>
          <Navigation />
        </CheckUserHOC>
      </UserProvider>
    </div>
  );
}

const CheckUserHOC = ({ children }) => {
  const { setUser } = React.useContext(UserContext);

  React.useEffect(() => {
    const existingEmail = getCookie("email");
    const existingName = getCookie("name");
    setUser({
      name: existingName,
      email: existingEmail,
    });
  }, []);
  return <div>{children}</div>;
};
