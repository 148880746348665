import keepingItRealImg from "../images/personas/keeping-it-real.png";
import goodVibrationsImg from "../images/personas/good-vibrations.png";
import aLilLuxeImg from "../images/personas/a-lil-luxe.png";
import bringTheBlingImg from "../images/personas/bring-the-bling.png";
import glamour1 from "../images/personas/glamour-1.png";
import glamour2 from "../images/personas/glamour-2.png";
import partay1 from "../images/personas/partay-1.png";
import partay2 from "../images/personas/partay-2.png";
import naughty1 from "../images/personas/naughty-1.png";
import naughty2 from "../images/personas/naughty-2.png";

export const personas = [
  {
    name: "Keeping it real",
    personaIndex: 1,
    description:
      "You’re not cheap, and soooo not basic. But you know money can’t buy you love or happiness. Being thrifty here and there means you can splash out where it really matters. You know what’s best ... and you definitely deserve it 👏🏽",
    image: {
      src: keepingItRealImg,
      alt: "Keeping it real",
    },
    futureLooks: [
      {
        text: "Glamour",
        images: [
          {
            src: glamour1,
            alt: "glamour1",
            width: 231.38,
            height: 231.38,
          },
          {
            src: glamour2,
            alt: "glamour2",
            width: 270.5,
            height: 271.75,
          },
        ],
      },
      {
        text: "PARTayyyys",
        images: [
          {
            src: partay1,
            alt: "partay1",
            width: 221,
            height: 208,
          },
          {
            src: partay2,
            alt: "partay2",
            width: 302.12,
            height: 243.57,
          },
        ],
      },
      {
        text: "A BIT OF NAUGHTY",
        images: [
          {
            src: naughty1,
            alt: "naughty1",
            width: 230.48,
            height: 229.67,
          },
          {
            src: naughty2,
            alt: "naughty2",
            width: 243.63,
            height: 244.17,
          },
        ],
      },
    ],
  },
  {
    name: "Good vibrations",
    personaIndex: 2,
    description:
      "It’s all about quality, not just the glitz and glam. Low key vibes and the occasional touch of somethang special. Magical moments need the perfect mix of ❤️🧠💸 And you’re willing to do the work so you can choose what suits you best. Good things, and good times 🙌🏽",
    image: {
      src: goodVibrationsImg,
      alt: "Good vibrations",
    },
    futureLooks: [
      {
        text: "Glamour",
        images: [
          {
            src: glamour1,
            alt: "glamour1",
            width: 231.38,
            height: 231.38,
          },
          {
            src: glamour2,
            alt: "glamour2",
            width: 270.5,
            height: 271.75,
          },
        ],
      },
      {
        text: "PARTayyyys",
        images: [
          {
            src: partay1,
            alt: "partay1",
            width: 221,
            height: 208,
          },
          {
            src: partay2,
            alt: "partay2",
            width: 302.12,
            height: 243.57,
          },
        ],
      },
      {
        text: "A BIT OF NAUGHTY",
        images: [
          {
            src: naughty1,
            alt: "naughty1",
            width: 230.48,
            height: 229.67,
          },
          {
            src: naughty2,
            alt: "naughty2",
            width: 243.63,
            height: 244.17,
          },
        ],
      },
    ],
  },
  {
    name: "A lil’ luxe",
    personaIndex: 3,
    description:
      "You’re ready to shine in your golden years, cos you deserve it. And splashing out from time to time, ain’t no crime! Soak up the journey, have some fun and celebrate the special moments. Life’s for living .. lovely, luscious and luxe.",
    image: {
      src: aLilLuxeImg,
      alt: "A lil’ luxe",
    },
    futureLooks: [
      {
        text: "Glamour",
        images: [
          {
            src: glamour1,
            alt: "glamour1",
            width: 231.38,
            height: 231.38,
          },
          {
            src: glamour2,
            alt: "glamour2",
            width: 270.5,
            height: 271.75,
          },
        ],
      },
      {
        text: "PARTayyyys",
        images: [
          {
            src: partay1,
            alt: "partay1",
            width: 221,
            height: 208,
          },
          {
            src: partay2,
            alt: "partay2",
            width: 302.12,
            height: 243.57,
          },
        ],
      },
      {
        text: "A BIT OF NAUGHTY",
        images: [
          {
            src: naughty1,
            alt: "naughty1",
            width: 230.48,
            height: 229.67,
          },
          {
            src: naughty2,
            alt: "naughty2",
            width: 243.63,
            height: 244.17,
          },
        ],
      },
    ],
  },
  {
    name: "Bring the bling",
    personaIndex: 4,
    description:
      "Life begins again at 60 and you’re livin’ large and livin’ bold cos there’s nothing better than splashin’ gold 💰 They’re called the golden years for a reason, right?! You’re not waiting for an invitation to join the Glitterati ... you’re throwing the party. Blinging on your terms.",
    image: {
      src: bringTheBlingImg,
      alt: "Bring the bling",
    },
    futureLooks: [
      {
        text: "Glamour",
        images: [
          {
            src: glamour1,
            alt: "glamour1",
            width: 231.38,
            height: 231.38,
          },
          {
            src: glamour2,
            alt: "glamour2",
            width: 270.5,
            height: 271.75,
          },
        ],
      },
      {
        text: "PARTayyyys",
        images: [
          {
            src: partay1,
            alt: "partay1",
            width: 221,
            height: 208,
          },
          {
            src: partay2,
            alt: "partay2",
            width: 302.12,
            height: 243.57,
          },
        ],
      },
      {
        text: "A BIT OF NAUGHTY",
        images: [
          {
            src: naughty1,
            alt: "naughty1",
            width: 230.48,
            height: 229.67,
          },
          {
            src: naughty2,
            alt: "naughty2",
            width: 243.63,
            height: 244.17,
          },
        ],
      },
    ],
  },
];
