import React from "react";
import { useNavigate } from "react-router-dom";
import CalculatorSteps from "../components/Calculator";
import CalcPrivacyPolicy from "../components/Calculator/CalcPrivacyPolicy";
import {
  PRIVACY_POLICY,
  STEPS_SCREEN,
} from "../constants/calculatorSteps";
import { getCookie } from "../utils/cookies";

function Calculator() {
  const [screen, setScreen] = React.useState(PRIVACY_POLICY);
  const navigate = useNavigate();

  React.useEffect(() => {
    const targetSuper = getCookie("targetSuper");
    if (!targetSuper) {
      navigate("/");
    }
  }, []);

  const renderScreen = () => {
    switch (screen) {
      case PRIVACY_POLICY:
        return <CalcPrivacyPolicy screen={screen} setScreen={setScreen} />;
      case STEPS_SCREEN:
        return <CalculatorSteps screen={screen} setScreen={setScreen} />;
      default:
        return null;
    }
  };

  return <div className="diamond-cursor">{renderScreen()}</div>;
}

export default Calculator;
