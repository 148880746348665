import React from "react";
import SingleScreen from "../shared/Layouts/SingleScreen";
import parse from "html-react-parser";
import { Player } from "@lottiefiles/react-lottie-player";
import { currencyFormatter, formatCurrency } from "../../utils/currencyInput";
import StepCounter from "../shared/StepCounter";
import { getCookie, setCookie } from "../../utils/cookies";
import { axiosInstance } from "../../utils/axios";
import leftSparks from "../shared/left-sparks.json";

function CalcTwoStepInput({ step, stepNumber, setStepNumber, stepTotal }) {
  const [inputValue, setInputValue] = React.useState("");
  const [message, setMessage] = React.useState("");

  const currentYear = new Date().getFullYear();

  const savedAnswer = getCookie(step.field);

  React.useEffect(() => {
    if (savedAnswer) {
      if (step.type === "money") {
        setInputValue(currencyFormatter.format(savedAnswer));
      } else {
        setInputValue(savedAnswer);
      }
    }
  }, []);

  const handleChange = (e) => {
    if (step.type === "age") {
      console.log(e.target.value);
      if (e.target.value > currentYear) {
        e.preventDefault();
        return;
      }
    }

    setInputValue(e.target.value);
    if (e.target.value !== "") {
      const currentYear = new Date().getFullYear();
      const yearborn = +e.target.value;
      const message = `Nice! <span className="font-bold">${
        currentYear - yearborn
      } years</span> of awesome.`;
      setMessage(message);
    } else {
      setMessage("");
    }
  };

  const handleSaveAnswer = () => {
    const sessionId =
      getCookie("sid") === "undefined" ? undefined : getCookie("sid");

    setStepNumber(stepNumber + 1);
    setCookie(step.field, Number(inputValue.replace(/[^0-9.-]+/g, "")), 7);
    //axiosInstance
    //  .post("/add-field", null, {
    //    params: { k: step.field, v: inputValue, sid: sessionId },
    //  })
    //  .then(function (response) {
    //    if (response.data.sid) {
    //      setCookie("sid", response.data.sid, 7);
    //    }
    //    setStepNumber(stepNumber + 1);
    //    setCookie(step.field, Number(inputValue.replace(/[^0-9.-]+/g, "")), 7);
    //  })
    //  .catch(function (error) {
    //    console.log(error);
    //  });
  };

  return (
    <SingleScreen
      onClick={handleSaveAnswer}
      buttonText="Continue"
      backgroundColor="cream"
      buttonDisabled={inputValue === ""}
      type="progress"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      redirectPath={stepNumber === stepTotal ? "/calculation" : null}
    >
      <div className="flex-1 flex flex-col items-center justify-start pt-20 relative">
        <div className="mb-6">
          <StepCounter
            stepNumber={stepNumber}
            stepTotal={stepTotal}
            setStepNumber={setStepNumber}
          />
        </div>
        <h1 className="text-4xl text-center">{step.heading}</h1>
        {step.paragraph && (
          <div className="mt-4 text-center">{step.paragraph}</div>
        )}
        <div className="relative">
          <input
            type={step.type === "age" ? "number" : "text"}
            placeholder={step.input.placeholder}
            className="outline-0 font-bold bg-cream text-5xl w-full italic text-center mt-10"
            value={inputValue}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.keyCode >= 65 && e.keyCode <= 90) {
                e.preventDefault();
              }
            }}
            onKeyUp={(e) => {
              if (step.type === "money") {
                const moneyOutput = formatCurrency(e.target);
                setInputValue(moneyOutput);
              }
            }}
          />
          {step.floatIcons?.length &&
            step.floatIcons.map((icon, index) => {
              return (
                <div key={index}>
                  <img
                    src={icon.src}
                    alt={icon.alt}
                    width={icon.width}
                    height={icon.height}
                    className={`absolute ${
                      icon.position === "left"
                        ? "-top-2 left-0"
                        : "-bottom-12 right-0"
                    }`}
                  />
                </div>
              );
            })}
        </div>
        {step.type === "age" && <div className="mt-24">{parse(message)}</div>}
        <div className="hidden">
          <Player
            autoplay
            loop
            src={leftSparks}
            style={{
              height: "300px",
              width: "300px",
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          ></Player>
        </div>
      </div>
    </SingleScreen>
  );
}

export default CalcTwoStepInput;
