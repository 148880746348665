import React from "react";
import {
  BackButtonIcon,
  LeftArrowIcon,
  SuperfierceBlackLogo,
} from "../../images/icons";
import SfLogo from "../../images/sf-logo.png";
import heroLogos from "../../images/mockImages/heroLogos.png";
import { useNavigate } from "react-router-dom";

function LoginHeader() {
  const navigate = useNavigate();

  return (
    <section className="py-4 flex items-center justify-between">
      <button
        className="lg:hidden block"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackButtonIcon />
      </button>
      <button
        className="hidden lg:flex border border-shark p-2 items-center gap-2 hover:bg-white"
        onClick={() => {
          navigate("/");
        }}
      >
        <LeftArrowIcon /> <p>Go Back</p>
      </button>
      <div className="lg:hidden block">
        <img width={32} height={32} src={SfLogo} alt="sf-logo" />
      </div>
      <div className="hidden lg:block">
        <SuperfierceBlackLogo />
      </div>
    </section>
  );
}

export default LoginHeader;
