import React, { useState, useEffect } from "react";

function TypingText({ originalText, setGameTab, currentGameTab }) {
  const [text, setText] = useState("");
  const [fullText, setFullText] = useState(originalText);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 50);
    } else {
      setTimeout(() => {
        setGameTab(currentGameTab + 1);
      }, 1500);
    }
  }, [index]);

  return (
    <div className="text-7xl font-bold mt-10 text-center lg:w-3/5 mx-auto">
      {text}
    </div>
  );
}

export default TypingText;
