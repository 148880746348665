import React from "react";
import { useNavigate } from "react-router-dom";
import SingleScreen from "../../shared/Layouts/SingleScreen";
import CelebImg from "../../../images/mockImages/celeb.png";
import TearDropImg from "../../../images/mockImages/tear-drop.png";
import MemeKid from "../../../images/mockImages/meme-kid.png";
import { getCookie } from "../../../utils/cookies";
import { currencyFormatter } from "../../../utils/currencyInput";

function NegativeCalculation({ stepNumber, setStepNumber }) {
  const [tabNumber, setTabNumber] = React.useState(0);

  const renderPositiveTabs = () => {
    switch (tabNumber) {
      case 0:
        return (
          <FirstTab
            type="progress"
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
          />
        );
      default:
        return <div>Invalid positive tab</div>;
    }
  };

  return <div>{renderPositiveTabs()}</div>;
}

const FirstTab = ({ setTabNumber, tabNumber, stepNumber, setStepNumber }) => {
  const amountMissing = getCookie("targetSuper") - getCookie("actualSuper");
  const navigate = useNavigate();

  return (
    <SingleScreen
      backgroundColor="cinnabar"
      buttonText="Get My Free Report"
      onClick={() => navigate("/getyourreport")} 
      footerText="How we worked out your retirement savings projection"
      type="progress"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
    >
      <div
        className="text-5xl lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center justify-center "
        style={{ fontWeight: 500, lineHeight: "4rem" }}
      >        
        <div className="flex-1 flex flex-col items-center justify-center ">
          <div className="text-5xl">Uh oh. Looks like you need an extra</div>
          <span className="text-5xl  font-bold text-white">
            {currencyFormatter.format(amountMissing)}
          </span>

          <div className="text-3xl" style={{ marginTop: "64px", fontWeight: 400, lineHeight: "3rem" }} >
            Don’t worry we’ll show you how to change that. Your dream life is closer than you think.
          </div>
        </div>
      </div>
    </SingleScreen>
  );
};

export default NegativeCalculation;
