import React from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { DoubleChevronLeft, DoubleChevronRight } from "../../images/icons";
import { getMediaUrl } from "../../utils/urls";
import CommonFooter from "../shared/CommonFooter";
import CommonHeader from "../shared/CommonHeader";
import { axiosInstance } from "../../utils/axios";
import { getCookie, setCookie } from "../../utils/cookies";
import { useNavigate } from "react-router-dom";

const SecondStep = ({
  item,
  stepCircles,
  setStepNumber,
  setGameTab,
  selectedOption,
  setSelectedOption,
  stepNumber,
}) => {
  const navigate = useNavigate();

  const answerObj = getCookie("answerObj");
  const answerObject = answerObj ? JSON.parse(answerObj) : null;
  const savedAnswer = answerObj ? answerObject[stepNumber] : null;

  const showCardText = (index) => {
    if (
      !document
        .querySelector(`#option-label-${index}`)
        .classList.contains("active")
    ) {
      document.querySelector(`#option-label-${index}`).classList.add("active");
    } else {
      document
        .querySelector(`#option-label-${index}`)
        .classList.remove("active");
    }
  };

  const handleSelectAnswer = (option, index) => {
    
    // console.log("handleSelectAnswer")

    window.dataLayer.push({
      event: 'individual_steps',
      eventProps: {
          category: "blingo",
          label: "completed",
          value: stepNumber
      }
    });

    
    const sessionId =
      getCookie("sid") === "undefined" ? undefined : getCookie("sid");

    let answerObject = getCookie("answerObj")
      ? JSON.parse(getCookie("answerObj"))
      : {};

    answerObject[item.order] = index + 1;

    let saveAnswerObj = JSON.stringify(answerObject);


    setCookie("answerObj", saveAnswerObj, 7);

    setSelectedOption(option.id);
    showCardText(index);
    setGameTab(2);

    axiosInstance
      .post("/add-field", null, {
        params: { k: stepNumber, v: index + 1, sid: sessionId },
      })
      .then(function (response) {
        if (response.data.sid) {
          setCookie("sid", response.data.sid, 7);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        console.log("added field");
      });
  };

  return (
    <div
      className={`flex flex-col justify-between h-screen overflow-auto diamond-cursor bg-${item.theme_color}`}
    >
      <div className="container mx-auto">
        <CommonHeader
          navigateBack={() => {
            if (stepNumber === 1) {
              navigate(-1);
            } else {
              setStepNumber(stepNumber - 1);
            }
          }}
        />
      </div>
      <div className=" flex flex-col pt-36 lg:pt-16 container mx-auto">
        <div className="flex items-center justify-center  gap-2">
          {stepCircles.map((num) => (
            <div
              key={num}
              className={`block rounded-full  ${
                item.order >= num + 1 ? "bg-shark" : "bg-lightPink"
              }`}
              style={{ width: 8, height: 8 }}
            ></div>
          ))}
        </div>
        <p className="text-center italic text-3xl mt-4">{item.question}</p>
        <div className="text-autofill flex items-center justify-center gap-2 mt-4 lg:hidden">
          <DoubleChevronLeft />
          <span>swipe for answers</span>
          <DoubleChevronRight />
        </div>
        <div className="mt-10">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={"true"}
            className="swiper-width"
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {item.options.map((option, index) => (
              <SwiperSlide
                key={index}
                className={`mx-4 lg:border-0 `}
                style={{ width: 203 }}
                onMouseEnter={() => {
                  showCardText(index);
                }}
                onMouseLeave={() => {
                  showCardText(index);
                }}
                onClick={() => {
                  handleSelectAnswer(option, index);
                }}
              >
                <div className=" relative">
                  <img
                    src={getMediaUrl(option.image.data.url)}
                    alt={option.image.data.caption}
                  />
                  <p
                    id={`option-label-${index}`}
                    className={` option-label-animate  absolute top-0 text-white bg-cinnabar w-full h-full p-4 text-center flex items-center justify-center ${
                      savedAnswer === index + 1 ? "active" : ""
                    }`}
                  >
                    {option.title}
                  </p>
                </div>
                {/* <p className="text-center text-black mt-2 md:hidden text-lg italic"> */}
                <p className={`text-center mt-2 md:hidden text-lg italic  ${
                  item.theme_color == "cream" ? "text-black" : "text-white"
                }`}>
                  {option.title}
                </p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default SecondStep;
