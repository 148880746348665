import React from "react";
import StepCounter from "../shared/StepCounter";
import { CloseIconCircle, FileUploadIcon } from "../../images/icons";
import FindDeetsModal from "../shared/FindDeetsModal";

function ThirdReportStep({ stepNumber, setStepNumber, formData, setFormData }) {
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [openFindDeetsModal, setOpenFindDeetsModal] = React.useState(false);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length) {
      // console.log(e.target.files);
      const reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImg(e.target.result);
      };

      reader.readAsDataURL(e.target.files[0]);
      setFormData((prevState) => ({
        ...prevState,
        frm_screenshot: e.target.files[0],
      }));
    }
  };

  return (
    <div>
      <h2 className="text-3xl mb-6 lg:text-4xl lg:w-3/4 ">
        Get your free{" "}
        <span className="text-cinnabar font-bold italic">
        Super Savings Report
        </span>
      </h2>
      <p className="text-sm mb-6">We need some info first...</p>
      <div className="mb-6">
        <StepCounter
          stepTotal={3}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          color="cinnabar"
        />
      </div>
      <div className="mb-4">
        <p className="font-bold text-sm">Latest super statement screenshot</p>
        <p
          className="text-sm cursor-pointer italic underline"
          onClick={() => {
            setOpenFindDeetsModal(true);
          }}
        >
          How do I find my super deets?
        </p>
      </div>
      <div className="border border-autofill relative h-48 flex items-center justify-center">
        <input
          type="file"
          className="w-full h-full absolute z-10 opacity-0"
          name="frm_screenshot"
          onChange={handleFileChange}
        />
        {selectedImg ? (
          // <div className="p-10 ">
          <div>
            <div className="relative">
              <img src={selectedImg} alt="selected-img" style={{ maxHeight: 165 }} />
              <p className="text-center text-sm">Photo Uploaded!</p>
              <div
                className="absolute top-0 -right-4 z-20"
                onClick={() => {
                  setSelectedImg(null);
                }}
              >
                <CloseIconCircle />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-2">
            <FileUploadIcon />
            <p className="text-autofill">Take a photo or upload</p>
          </div>
        )}
      </div>
      <FindDeetsModal
        openModal={openFindDeetsModal}
        setOpenModal={setOpenFindDeetsModal}
      />
    </div>
  );
}

export default ThirdReportStep;
