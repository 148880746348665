import React from "react";
import SingleScreen from "../../shared/Layouts/SingleScreen";
import CelebImg from "../../../images/mockImages/celeb.png";

function PositiveCalculation({ stepNumber, setStepNumber }) {
  const [tabNumber, setTabNumber] = React.useState(0);

  const renderPositiveTabs = () => {
    switch (tabNumber) {
      case 0:
        return (
          <FirstTab
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
          />
        );
      case 1:
        return (
          <SecondTab
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
          />
        );
      default:
        return <div>Invalid positive tab</div>;
    }
  };

  return <div>{renderPositiveTabs()}</div>;
}

const FirstTab = ({ setTabNumber, tabNumber, stepNumber, setStepNumber }) => {
  const [showImage, setShowImage] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowImage(true);
    }, 50);
    setTimeout(() => {
      setTabNumber(tabNumber + 1);
    }, 4000);
  }, []);

  return (
    <SingleScreen
      showFooterInMobile={false}
      backgroundColor="cinnabar"
      buttonText="CONTINUE"
      type="progress"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
    >
      <div className="flex-1 flex flex-col items-center justify-center ">
        <div className="relative">
          <p
            className="lg:w-1/2 lg:mx-auto text-5xl  text-center "
            style={{ fontWeight: 500, lineHeight: "4rem" }}
          >
            <span className="text-5xl  font-bold text-white"> Oh em gee!</span>{" "}
            Looks like you’re on track to your dream life.
          </p>
          {showImage && (
            <div className="absolute -top-56 lg:-top-48 lg:left-20 transform -rotate-12  border-2 border-white">
              <iframe
                src="https://giphy.com/embed/artj92V8o75VPL7AeQ"
                width="192"
                height="192"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
                title="positive"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </SingleScreen>
  );
};

const SecondTab = ({ stepNumber, setStepNumber }) => {
  return (
    <SingleScreen
      backgroundColor="cinnabar"
      buttonText="Get My Free Report"
      onClick={() => {}}
      redirectPath="/getyourreport"
      footerText={"How we worked out your retirement savings projection"}
      type="progress"
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
    >
      <div className="lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center justify-center ">
        <div
          className="text-4xl"
          style={{ fontWeight: 500, lineHeight: "3rem" }}
        >
          Wanna see if we can top up your pile of
        </div>
        <div>
          <span className="text-7xl italic font-bold text-white">gold?</span>
        </div>
      </div>
    </SingleScreen>
  );
};

export default PositiveCalculation;
