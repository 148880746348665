import React from "react";
import Game from "../components/Game";
const appSettings = window.appSettings;

function Play() {
  const [stepNumber, setStepNumber] = React.useState(1);
  const [questions, setQuestions] = React.useState(null);

  React.useEffect(() => {
    const fetchApi = async () => {
      const questionsRes = await fetch(
        appSettings.cmsApiUrl +
          "/questions?populate[options][populate]=*"
      );
      const questionsJson = await questionsRes.json();
      setQuestions(questionsJson?.data);
    };
    fetchApi();
  }, []);

  return (
    <div>
      <div className="hidden bg-apricot bg-blue bg-cinnabar bg-pink diamond-cursor"></div>
      {questions &&
        questions?.map(
          (question, index) =>
            stepNumber === question.order && (
              <Game
                item={question}
                key={index}
                setStepNumber={setStepNumber}
                numOfQuestions={questions.length}
                stepNumber={stepNumber}
              />
            )
        )}
    </div>
  );
}

export default Play;
