import React from "react";
import {
  OutlineFacebookIcon,
  OutlineInstagramIcon,
  OutlineLinkedInIcon,
} from "../../images/icons";
import CommonFooter from "../shared/CommonFooter";
import CommonHeader from "../shared/CommonHeader";
import TypingText from "../shared/TypingText";
import { useNavigate } from "react-router-dom";

const FirstStep = ({
  item,
  stepCircles,
  setStepNumber,
  setGameTab,
  stepNumber,
}) => {
  const navigate = useNavigate();
  return (
    <section
      className={`bg-${item.theme_color} h-screen overflow-auto flex flex-col justify-between diamond-cursor`}
    >
      <div className="container mx-auto ">
        <CommonHeader
          navigateBack={() => {
            if (stepNumber === 1) {
              navigate(-1);
            } else {
              setStepNumber(stepNumber - 1);
            }
          }}
        />
      </div>
      <div className="container mx-auto flex flex-col pt-36 items-center h-full">
        <div>
          <div className="flex items-center justify-center  gap-2">
            {stepCircles.map((num) => (
              <div
                key={num}
                className={`block rounded-full  ${
                  item.order >= num + 1 ? "bg-shark" : "bg-lightPink"
                }`}
                style={{ width: 8, height: 8 }}
              ></div>
            ))}
          </div>
          <TypingText
            originalText={item.question}
            setGameTab={setGameTab}
            currentGameTab={0}
          />
        </div>
      </div>
      <CommonFooter />
    </section>
  );
};
export default FirstStep;
