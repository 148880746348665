import cameraIcon from "../images/icons/camera.png";
import unicornIcon from "../images/icons/unicorn.png";
import surfingIcon from "../images/icons/surfing.png";

export const resultScrollBannerTexts = [
  {
    text: "Opportunistic",
    icon: cameraIcon,
  },
  {
    text: "Spontaneous",
    icon: unicornIcon,
  },
  {
    text: "Adventurous",
    icon: surfingIcon,
  },
];
