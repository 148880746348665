import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { FacebookIcon, GoogleIcon } from "../../images/icons";
import { getCookie, setCookie } from "../../utils/cookies";
import LoginHeader from "./LoginHeader";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { axiosInstance } from "../../utils/axios";
import axios from "axios";

const appSettings = window.appSettings;

function LoginMain({ loginComplete }) {
  const { setUser } = React.useContext(UserContext);
  const [formData, setFormData] = React.useState({ name: "", email: "" });
  const [errorMessage, setErrorMessage] = React.useState("");

  const sessionId =
    getCookie("sid") === "undefined" ? undefined : getCookie("sid");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidFormData = (formInputs) => {
    if (formInputs.name === "" || formInputs.email === "") {
      setErrorMessage("All fields are required*");
      return false;
    }

    if (!validateEmail(formInputs.email)) {
      setErrorMessage("Invalid email format");
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidFormData(formData)) {
      setCookie("name", formData.name, 7);
      setCookie("email", formData.email, 7);
      setUser({
        name: formData.name,
        email: formData.email,
      });

      const vParams = formData.name + "," + formData.email;

      loginComplete();

      axios.all([
        //axios.post(appSettings.dataCollectionUrl + "/add-field", null, {
        //  params: { k: "name,email", v: vParams, sid: sessionId },
        //}), 
        axios.post(appSettings.hubspotUrl + '/add-contact?code=' + appSettings.hubspotApi_key, {
          "email": formData.email,
          "fullname": formData.email,
          "prop": "blingo_sign_up",
          "val": "Yes"
        })
      ])
      .then(axios.spread((data1, data2) => {
        if (data1.data.sid) {
          setCookie("sid", data1.data.sid, 7);
        }
        if (data2.data.hsid) {
          setCookie("hsid", data2.data.hsid, 7);
        }
        
      }))
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        console.log("added field");
      });;
    }
  };

  const responseFacebook = (response) => {
    if (response.name && response.email) {
      console.log(response);
      setCookie("name", response.name);
      setCookie("email", response.email);
      const vParams = response.name + "," + response.email;
      //axiosInstance
      //  .post(appSettings.dataCollectionUrl + "/add-field", null, {
      //    params: { k: "name,email", v: vParams, sid: sessionId },
      //  })
      //  .then((res) => {
      //    if (res.data.sid) {
      //      setCookie("sid", res.data.sid, 7);
      //    }
      //    loginComplete();
      //  })
      //  .catch(function (error) {
      //    console.log(error);
      //  })
      //  .finally(() => {
      //    console.log("added field");
      //  });
    }
  };

  const responseGoogle = (response) => {
    console.log("response", response);
    if (response.profileObj.name && response.profileObj.email) {
      console.log(response);
      setCookie("name", response.profileObj.name);
      setCookie("email", response.profileObj.email);
      const vParams =
        response.profileObj.name + "," + response.profileObj.email;
        loginComplete();
      //axiosInstance
      //  .post(appSettings.dataCollectionUrl + "/add-field", null, {
      //    params: { k: "name,email", v: vParams, sid: sessionId },
      //  })
      //  .then((res) => {
      //    if (res.data.sid) {
      //      setCookie("sid", res.data.sid, 7);
      //    }
      //    loginComplete();
      //  })
      //  .catch(function (error) {
      //    console.log(error);
      //  })
      //  .finally(() => {
      //    console.log("added field");
      //  });
    }
  };

  const componentClicked = () => {
    console.log("fb clicked");
  };

  return (
    <div className="bg-cinnabar h-screen overflow-auto">
      <div className="container mx-auto">
        {/* Login header */}
        <LoginHeader />
        <section className="bg-white p-8 border border-shark lg:max-w-md mx-auto lg:mb-20 mb-10">
          <div>
            <h1 className="text-3xl text-shark">
              Ready to play?{" "}
            </h1>
            <div className="flex flex-col gap-4 mt-3">
              <FacebookLogin
                appId={appSettings.facebook_app_id}
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
                textButton="Continue with Facebook"
                icon={<FacebookIcon />}
                cssClass="white-button w-full flex items-center gap-2 justify-center"
              />
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                icon={true}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                buttonText="Continue with Google"
                className="white-button w-full flex items-center gap-2 justify-center"
              />
            </div>
          </div>

          <div className="flex hidden items-center justify-center mt-6 relative h-6 ">
            <p className="absolute top-0 bg-white z-10 px-2">OR</p>
            <div className="border-t border-iron w-full absolute top-1/2"></div>
          </div>

          <div className="mt-6">
            <p className="text-xl">Let's get started - drop your deets below:</p>
            <form className="mt-6" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <label className="text-sm">Full name*</label>
                  <input
                    placeholder="Enter full name"
                    className="text-shark border border-autofill p-2 w-full outline-0"
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                    }}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label className="text-sm">Email*</label>
                  <input
                    placeholder="Enter email"
                    className="text-shark border border-autofill p-2 w-full outline-0"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <p className="text-cinnabar">
                    {errorMessage ? errorMessage : ""}
                  </p>
                </div>
              </div>
              <button
                type="submit"
                className="black-button w-full mt-20 lg:mt-4"
              >
                Let’s Play
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default LoginMain;
