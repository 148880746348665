import React from "react";
import logo from "../images/grow-old.png";
import logoMobile from "../images/gg_logo_hoz.png";
import subLogo from "../images/subLogo.png";
import { Link } from "react-router-dom";

function Header({ hideOnMobile = false }) {
  return (
    <div
      className={`sticky top-0  bg-white border-b border-shark z-30 ${
        hideOnMobile ? "lg:block hidden" : ""
      }`}
    >
      <div className="flex items-center justify-between py-3 container mx-auto">
        <div className="flex items-center gap-6">
          <Link to="/">
            <img src={logo} alt="site-logo" width={89} height={37} className="hidden lg:block"/>        
          </Link>
          <a href="https://www.superfierce.com.au" target="_blank">
            <img
              className="hidden lg:block"
              src={subLogo}
              alt="subLogo"
              width={156}
              height={22}
            />
          </a>
        </div>
      </div>
      <div className="flex items-center justify-center py-1">
        <Link to="/">
          <img src={logoMobile} alt="site-logo" width={160} height={60} className="lg:hidden"/>
        </Link>
      </div>
    </div>
  );
}

export default Header;
