import React from "react";
import { STEPS_SCREEN } from "../../constants/calculatorSteps";
import SingleScreen from "../shared/Layouts/SingleScreen";

function CalcPrivacyPolicy({ setScreen }) {
  return (
    <SingleScreen
      backgroundColor="cream"
      onClick={() => {
        setScreen(STEPS_SCREEN);
      }}
      buttonText="CONTINUE"
    >
      <div className=" flex-1 flex flex-col items-center justify-center">
        <h2 className="text-3xl text-center" style={{ fontWeight: 200 }}>
          Firstly, we need a lil bit of info from you...
        </h2>
        <p className="mt-10 text-center">
          Rest assured, your personal info is kept safe and secure in
          Australian-based servers. For extra peace of mind, check out our{" "}
        </p>
        <a href="https://superfierce.com.au/privacy-policy/" className="underline font-bold mt-4" target="_blank">
          PRIVACY POLICY
        </a>
      </div>
    </SingleScreen>
  );
}

export default CalcPrivacyPolicy;
