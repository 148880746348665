import React from "react";

function StepCounter({
  stepTotal,
  stepNumber,
  setStepNumber,
  color = "shark",
}) {
  const stepCircles = Array.from(Array(stepTotal).keys());
  return (
    <div className="flex items-center justify-center  gap-2">
      {stepCircles.map((num) => (
        <div
          key={num}
          className={`block rounded-full  ${
            stepNumber >= num + 1 ? `bg-${color}` : "bg-lightPink"
          }`}
          style={{ width: 8, height: 8 }}
        ></div>
      ))}
    </div>
  );
}

export default StepCounter;
