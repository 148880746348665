import superCalcData from "./superCalc.json";
import { axiosInstance } from "./axios";
import { getSalaryRange } from "./report";

const appSettings = window.appSettings;

const interestRateTarget = 0.045;
const interestRateActual = 0.06975; 
const lifeExpectancy = 92;
const retirementAge = 67;
const CPI = 0.02;

const yearsOfRetirement = (lifeExpectancy-retirementAge); 

const employerContributionRate = 0.120;
const superInvestmentFeeRate = 0.0085;
const superInsurancePa = 0;
const superTaxRate = 0.07;

function conv_number(expr, decplaces) {
  var str = "" + Math.round(eval(expr) * Math.pow(10,decplaces));
  while (str.length <= decplaces) {
    str = "0" + str;
  }
  var decpoint = str.length - decplaces;
  return (str.substring(0,decpoint) + "." + str.substring(decpoint,str.length));
}

// Parameters are rate, total number of periods, payment made each period and future value
function pv(rate, nper, pmt, fv) {
  rate = parseFloat(rate);
  nper = parseFloat(nper);
  pmt = parseFloat(pmt);

  fv = (fv) ? parseFloat(fv) : 0;
  let pv_value = 0;
  if ( nper == 0 ) {
    return(0);       
  }
  if ( rate == 0 ) { 
    pv_value = -(fv + (pmt * nper));
  } else {
    let x = Math.pow(1 + rate, -nper); 
    let y = Math.pow(1 + rate, nper);
    pv_value = - ( x * ( fv * rate - pmt + y * pmt )) / rate;
  }
  pv_value = conv_number(pv_value,2);
  return (pv_value);
}

function fv(rate, nper, pmt, pv) {
  rate = parseFloat(rate);
  nper = parseFloat(nper);
  pmt = parseFloat(pmt);

  pv = (pv) ? parseFloat(pv) : 0;
  let fv_value = 0;
  if ( nper == 0 ) {
    return(0);
  }
  if ( rate == 0 ) { 
    fv_value = -(pv + (pmt * nper));
  } else {
    let x = Math.pow(1 + rate, nper);
    fv_value = - ( -pmt + x * pmt + rate * x * pv ) /rate;
  }
  fv_value = conv_number(fv_value,2);
  return (fv_value);
}

function mode(a){
  return Object.values(
    a.reduce((count, e) => {
      if (!(e in count)) {
        count[e] = [0, e];
      }
      
      count[e][0]++;
      return count;
    }, {})
  ).reduce((a, v) => v[0] < a[0] ? a : v, [0, null])[1];
};

export const calculateTargetSuper = (answerObj) => {

  try{
    if(!answerObj) throw "missing answer object";

    const questionKeys = Object.keys(answerObj);
    const questionNum = questionKeys.length; 
    let answerTotal = 0, weeklyTotal = 0, avgAnswer = 0;
    let answerArr = [];

    for(var q = 0; q < questionNum; q++){
      var question = questionKeys[q];
      var answer = parseInt(answerObj[question]);
      answerArr.push(answer);
      answerTotal += answer;

      let amountObj = superCalcData[question].option[answer];

      if(amountObj === undefined){
        console.log("question: " + question + "\nanswer: " + answer + "\namountObj: " + amountObj);
        throw "answer index out of range";
      }

      var amount = parseFloat(amountObj);

      weeklyTotal += amount;

      // console.log("amount["+question+"]: " + amount);
    }

    avgAnswer = Math.round(answerTotal / questionNum);
    var answerMode = mode(answerArr);

    // console.log("answerMode: " + answerMode);

    let groceries = superCalcData["groceries"].option[avgAnswer];
    // console.log("amount[groceries]: " + groceries);

    // console.log("weeklyTotal: " + weeklyTotal);

    weeklyTotal += groceries;

    // console.log("answerTotal: " + answerTotal + " " + avgAnswer);
    // console.log("weeklyTotal(w groceries): " + weeklyTotal);

    var requiredSaving = -pv(interestRateTarget, yearsOfRetirement, (weeklyTotal*52));

    return {
      "requiredSaving" : Math.round(requiredSaving), 
      "weeklyTotal": Math.round(weeklyTotal),
      "personaIndex" : answerMode};
  }catch(err){
    return {"error" : err};
  }
  
}

export const calculateActualSuper = async(yearBorn, salaryPa, currentBalance) => {
  
  try{
    const today = new Date();
    const age = today.getFullYear() - yearBorn;
    const salaryRange = getSalaryRange(salaryPa);

    // const yearsRemaining = retirementAge - age;
    // const superContribution = (salaryPa * employerContributionRate);

    const res = await axiosInstance
      .post(appSettings.fierceAppUrl + "/api/internal/average/enquire?pt=today", {
      // .post("https://stg-app.superfierce.com.au" + "/api/internal/average/enquire?pt=today", {
        // params: {
          "age": age,
          "balance": currentBalance,
          "gender": "female", 
          "salaryRange": salaryRange
        // },
      }, {timeout: 10000});

      // console.log(res)

      // console.log("averageBalance: " + res.data.doNothing.finalBalance);
      const actualSuper = res.data.doNothing.finalBalance;

      // .then((res) => {
      //   console.log("finished");
      //   console.log(res);
      //   console.log(res.headers);

      //   return {"actualSuper" : Math.round(11111)};
      //   // console.log(result);
      // })
      // .catch((e) => {
      //   console.log("error");
      //   console.log(e);
      // });

    // const adjSuperContributionPa = (superContribution - ((currentBalance * superInvestmentFeeRate) + superInsurancePa)); //fees, insurance, etc..
    // const adjSuperContributionPa = (1-superTaxRate) * (superContribution - ((currentBalance * superInvestmentFeeRate) + superInsurancePa)); //fees, insurance, etc..

    // console.log("adjSuperContribution:" + adjSuperContributionPa +
                // "\nsuperContribution:" + superContribution +
                // "\ncurrentBalance:" + currentBalance);    
    
    // let actualSuper = -fv(interestRateActual, yearsRemaining, adjSuperContributionPa, currentBalance) / (1+CPI) ^ (yearsRemaining);
    // let actualSuper = -fv(interestRateActual, yearsRemaining, adjSuperContributionPa, currentBalance);
    
    
    
    return {"actualSuper" : Math.round(actualSuper)};
  }catch(err){
    return {"error" : err};
  }
}