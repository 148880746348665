import React from "react";
import { sharingOptions } from "../../constants/sharingOptions";
import { CloseIconCircle } from "../../images/icons";
import { getCookie } from "../../utils/cookies";
import Modal from "./Modal";

const ShareResultModal = ({ openModal, setOpenModal, persona }) => {
  const [copied, setCopied] = React.useState(false);
  const origin = window.location.origin;
  const linkToShare = `${origin}/shareresults?name=${getCookie(
    "name"
  )}&personaIndex=${persona?.personaIndex}`;

  const handleOptionClick = (option) => {
    if (option.type === "copyUrl") {
      navigator.clipboard.writeText(linkToShare);
      setCopied(true);
    }
  };

  const renderOption = (option) => {
    if (option.type === "copyUrl") {
      return (
        <CopyLinkOption
          option={option}
          handleOptionClick={handleOptionClick}
          copied={copied}
        />
      );
    }

    if (option.type === "facebook") {
      return <FacebookOption option={option} linkToShare={linkToShare} />;
    }
  };

  

  return (
    <Modal open={openModal} setOpen={setOpenModal} maxWidth={350}>
      <div className="flex items-center justify-between border-b border-iron pb-4">
        <p>Share results board</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIconCircle />
        </div>
      </div>
      <div className="flex flex-col gap-4 pt-4">
        {sharingOptions.map((option, index) => {
          return <div key={index}>{renderOption(option)}</div>;
        })}
      </div>

      <div id="fb-root"></div>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v14.0&appId=746265232861705&autoLogAppEvents=1"
        nonce="DvVPuyBe"
      ></script>
    </Modal>
  );
};

const CopyLinkOption = ({ handleOptionClick, option, copied }) => (
  <div
    className="flex items-center justify-between cursor-pointer gap-6"
    onClick={() => {
      window.dataLayer.push({ event: 'persona_shared_link', });
      handleOptionClick(option);
    }}
  >
    <div className="flex flex-items gap-4">
      <div>{option.icon}</div>
      <p className="text-sm">{option.text}</p>
    </div>
    {option.type === "copyUrl" && copied && (
      <p className="text-xs text-autofill italic">copied!</p>
    )}
  </div>
);


const FacebookOption = ({ option, linkToShare }) => (
  <div
    className="fb-share-button"
    data-href={linkToShare}
    data-layout="button_count"
    data-size="small"
  >
    <a
      target="_blank"
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        linkToShare
      )}&amp;src=sdkpreparse`}
      className="fb-xfbml-parse-ignore"
      onClick={(e) => window.dataLayer.push({ event: 'persona_shared_facebook', })}
      rel="noreferrer"
    >
      <div className="flex flex-items gap-4">
        <div>{option.icon}</div>
        <p className="text-sm">{option.text}</p>
      </div>
    </a>
  </div>
);

export default ShareResultModal;
