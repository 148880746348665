import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import noThanksImg from "../images/no-thanks.png";

function RequestReport() {

  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: document.location.origin + document.location.pathname,
      title: "Request Report"
    }
  });

  return (
    <div>
      <Header />
      <section className="bg-cream py-10">
        <div className="container mx-auto">
          <img
            src={noThanksImg}
            alt="noThanksImg"
            className="max-w-xs mx-auto"
          />
          <div className="p-4 bg-white border border-shark max-w-lg mx-auto">
            <h2 className="text-3xl text-center">
              Don't wanna play Blingo?
            </h2>
            <p className="text-cinnabar italic font-bold text-3xl text-center">
              Let's skip to the good part.
            </p>
            <p className="text-center text-sm mt-5">
              We get it: retirement probably feels like a looong way away. But the fact is, the earlier you think about it the better off you’ll be.
              We’re talking, like *at least $100,000 better off. Yes, that’s six figures.
            </p>
            <p className="text-center text-sm mt-5">
              We’ve built a world-first, independent financial calculator that’ll tell you exactly what you’re on track to retire with
               - and how to save an average of *$100,000 bucks by simply making sure your super is in the right fund.
            </p>
            <p className="text-center text-sm mt-5">
              Better yet? We’ll put this info into a <span style={{fontWeight: 'bold'}}>FREE, personalised report for you.</span> No strings, darl.
            </p>
            <div className="mt-6 flex flex-col gap-4">
              <Link to="/getyourreport">
                <button className="black-button w-full py-2">
                  get me my free report
                </button>
              </Link>
              <p className="italic text-xs">*The average saving amount an Australian person will make when switching their super to the lowest cost super
                fund in the market, calculated for their individual circumstances.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default RequestReport;
