import React from "react";
import BlockScrollBanner from "../components/Blocks/BlockScrollBanner";
import Footer from "../components/Footer";
import CommonHeader from "../components/shared/CommonHeader";
import { Link, useNavigate } from "react-router-dom";
import { calculateTargetSuper } from "../utils/superCalc";
import { getCookie, setCookie } from "../utils/cookies";
import { personas } from "../constants/personas";
import Modal from "../components/shared/Modal";
import ExplainerModal from "../components/shared/ExplainerModal";
import { CloseIconCircle, ScrollBannerIcon } from "../images/icons";
import { sharingOptions } from "../constants/sharingOptions";
import { currencyFormatter } from "../utils/currencyInput";
import ShareResultModal from "../components/shared/ShareResultModal";
import CommonMarquee from "../components/shared/CommonMarquee";
import { resultScrollBannerTexts } from "../constants/resultScrollBanner";

function Results() {
  const [calcResult, setCalcResult] = React.useState(null);
  const [persona, setPersona] = React.useState({});
  const [openExplainerModal, setOpenExplainerModal] = React.useState(false);
  const [openSharingModal, setOpenSharingModal] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {

    const answerObjectCookie = getCookie("answerObj");
    if (!answerObjectCookie) {
      navigate("/");
      return;
    }

    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname,
        title: "Your Results"
      }
    });

    const answerObject = JSON.parse(getCookie("answerObj"));
    const calculationResult = calculateTargetSuper(answerObject);
    setCalcResult(calculationResult);
    setCookie("targetSuper", calculationResult.requiredSaving, 7);
    setCookie("personaIndex", calculationResult.personaIndex, 7);
    setCookie("weeklyTotal", calculationResult.weeklyTotal, 7);
    setPersona(
      personas.find(
        (item) => item.personaIndex === calculationResult.personaIndex
      )
    );
  }, []);

  return (
    <div>
      <div className="bg-cinnabar">
        <div className="container mx-auto hidden lg:block">
          <CommonHeader
            buttonText={"Take Blingo Again"}
            navigateBack={() => {
              navigate("/playblingo");
            }}
          />
        </div>
      </div>
      <img
        src={persona?.image?.src}
        alt={"persona-img"}
        className="lg:hidden block w-full"
      />
      <section className="bg-cinnabar">
        <div className="container mx-auto py-10 lg:flex items-center justify-between">
          <div className="lg:max-w-lg">
            <div className="pb-10">
              <h1 className="text-4xl font-bold italic text-center w-1/2 mx-auto lg:text-6xl lg:text-left lg:w-full">
                <span className="text-white">{persona.name}</span>
              </h1>
            </div>
            <div className="p-4 bg-white">
              <p className="text-center mb-8 lg:text-left">
                {persona.description}
              </p>
              <p className="mb-4 text-3xl text-center lg:text-xl font-bold">
                For this retirement lifestyle, you’ll need
              </p>
              <p className="text-4xl text-cinnabar italic font-bold text-center">
              {currencyFormatter.format(calcResult?.weeklyTotal)} a week{" "}
              </p>
              <p className="text-center mt-3">
                or a balance of {currencyFormatter.format(calcResult?.requiredSaving)}
              </p>
              <div className="flex flex-col gap-2 mt-6">
                <Link to={`/bestlife`}>
                  <button className="black-button w-full">
                    Show me how to get the $$$
                  </button>
                </Link>
                <button
                  className="white-button"
                  onClick={() => {
                    setOpenSharingModal(true);
                  }}
                >
                  Share your Results
                </button>
              </div>
              <p
                className="mt-6 underline cursor-pointer text-center mb-4"
                onClick={() => {
                  setOpenExplainerModal(true);
                }}
              >
                How we worked out the cost of your dream life
              </p>
            </div>
          </div>
          <div className="border border-white p-2  rotate-3 hidden lg:block">
            <img
              src={persona?.image?.src}
              alt={"persona-img"}
              className="hidden lg:block max-w-md"
            />
          </div>
        </div>
      </section>
      <CommonMarquee
        componentList={resultScrollBannerTexts}
        seperator={<ScrollBannerIcon />}
        textStyle={"italic"}
      />
      <ShareResultModal
        openModal={openSharingModal}
        setOpenModal={setOpenSharingModal}
        persona={persona}
      />
      <ExplainerModal
        openModal={openExplainerModal}
        setOpenModal={setOpenExplainerModal}
      />
      <Footer />
    </div>
  );
}

export default Results;
