import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import BlockScrollBanner from "../Blocks/BlockScrollBanner";
import { getCookie, setCookie } from "../../utils/cookies";
import CommonHeader from "../shared/CommonHeader";
import { useNavigate } from "react-router-dom";
import { getMediaUrl } from "../../utils/urls";
import leftSparks from "../shared/left-sparks.json";
import rightSparks from "../shared/right-sparks.json";
import Lottie from "react-lottie";
import axios from "axios";

const appSettings = window.appSettings;

const FourthStep = ({
  item,
  stepCircles,
  setStepNumber,
  setGameTab,
  selectedOption,
  stepNumber,
  numOfQuestions
}) => {
  const navigate = useNavigate();
  const [lottie, setLottie] = React.useState(null);
  const DefaultAnswerTime = 2000;

  const hsid =
    getCookie("hsid") === "undefined" ? undefined : getCookie("hsid");

  React.useEffect(() => {
    if (stepNumber >= numOfQuestions) {
      // redirect to results page

      // setTimeout(() => {
      //   setStepNumber(stepNumber + 1);
      // }, 2000);
      // return;

      setTimeout(() => {
        navigate("/omgloading");
      }, DefaultAnswerTime);

      window.dataLayer.push({ event: 'bligo_done', eventProps: {}});

      if(hsid)
        axios.post(appSettings.hubspotUrl + '/add-property?code=' + appSettings.hubspotApi_key, {
          "hsid": hsid,
          "prop": "blingo_player",
          "val": "Yes"
        })

    }
    setTimeout(() => {
      setStepNumber(stepNumber + 1);
    }, DefaultAnswerTime);
  }, []);

  const currentAnswer = item.options.find(
    (option) => option.id === selectedOption
  );

  const hardBlock = {
    bannerText:
      "Living the dream of waking up and seeing your city come to life",
    style: "normal",
  };

  
  return (
    <div
      className={`bg-${item.theme_color} h-screen overflow-hidden relative diamond-cursor`}
    >
      <div className="container mx-auto">
        <CommonHeader
          navigateBack={() => {
            if (stepNumber === 1) {
              navigate(-1);
            } else {
              setStepNumber(stepNumber - 1);
            }
          }}
        />
      </div>
      <div className="flex flex-col pt-20 lg:pt-10 container mx-auto">
        <div className="border border-white w-max mx-auto blingo-answer-img">
          <img
            src={getMediaUrl(currentAnswer.image.data.url)}
            alt={currentAnswer.image.data.caption}
            className="max-w-xs"
          />
        </div>
        <p
          style={{ fontSize: "40pt" }}
          className="lg:text-7xl font-bold text-center my-10"
        >
          {currentAnswer.title}
        </p>
      </div>
      {/* <div className="absolute bottom-0 w-full">
        <BlockScrollBanner block={hardBlock} />
      </div> */}
      {/* <Sparks /> */}
      <Player
        autoplay
        loop
        src={leftSparks}
        style={{
          height: "300px",
          width: "300px",
          position: "absolute",
          top: -150,
        }}
      ></Player>
    </div>
  );
};

const Sparks = () => {
  const defaultOptionsLeft = {
    loop: true,
    autoplay: true,
    animationData: leftSparks,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsRight = {
    loop: true,
    autoplay: true,
    animationData: rightSparks,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Lottie
        options={defaultOptionsLeft}
        width={300}
        height={300}
        style={{
          height: "300px",
          width: "300px",
          position: "absolute",
          top: -80,
        }}
      ></Lottie>
      <Lottie
        options={defaultOptionsRight}
        width={300}
        height={300}
        style={{
          height: "300px",
          width: "300px",
          position: "absolute",
          bottom: 50,
          right: 0,
        }}
      ></Lottie>
    </>
  );
};

export default FourthStep;
