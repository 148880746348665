import React, { Suspense } from "react";
//import Block from "../components/Blocks";
//import Footer from "../components/Footer";
//import Header from "../components/Header";
import { landingPageData } from "../constants/mockData";
import { useSearchParams } from "react-router-dom";

const Footer = React.lazy(() => import('../components/Footer'));
const Header = React.lazy(() => import('../components/Header'));
const Block = React.lazy(() => import('../components/Blocks'));

const appSettings = window.appSettings;

function LandingPage() {
  const { blocks } = landingPageData;
  const [landingData, setLandingData] = React.useState([]);
  //const [isLoading, setIsLoading] = React.useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const banner_utm = {
    "retired_life": 0,
    "Super_Savings": 1,
    "gransta": 2,
    "future_lifestyle": 3
  }

  React.useEffect(() => {
    const fetchApi = async () => {
      const landingResponse = await fetch(
        appSettings.cmsApiUrl +
          "/landing-page?populate[instagram_feed][populate]=*&populate[video_list]=*&populate[faqs]=*&populate[partners][populate]=*&populate[main_banners][populate]=*"
      );
      const landingJson = await landingResponse.json();
      const retrievedLandingData = landingJson?.data;
      const retrievedKeys = Object.keys(retrievedLandingData);
      // console.log(retrievedKeys);
      let blockVar = blocks;
      const newBlocks = blockVar.map((block, index) => {
        if (retrievedKeys.includes(block.identifier)) {
          switch(block.identifier){
            case "scroll_banner_1":
            case "scroll_banner_2":
              block.bannerText = retrievedLandingData[block.identifier];
              break;
            case "video_list":
              block.videos = retrievedLandingData[block.identifier];
              break;
            case "instagram_feed":
              block.carouselImages = retrievedLandingData[block.identifier];
              break;
            case "faqs":
              block.faqs = retrievedLandingData[block.identifier];
              break; 
            case "main_banners":
              let utm_id = searchParams.get("utm_id");
              let startIdex = utm_id ? banner_utm[utm_id] : 0;
              block.startIndex = startIdex ? startIdex : 0;    
              block.marqueeImages = retrievedLandingData[block.identifier];
          }
        }

        //setIsLoading(false);

        return block;
      });
      // console.log(newBlocks);
      setLandingData(newBlocks);
    };
    fetchApi();

    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: document.location.origin + document.location.pathname,
        title: "Landing Page"
      }
    });

  }, []);

  
  return (
    <div>
      <Suspense fallback={<div className="landing-loading" />}>
        <Header />
        {
          landingData.map((block, index) => {
                return <Block key={index} block={block} />;
              })
        }
        <Footer delayFooter="false" />
      </Suspense>     
    </div>
  );
}

export default LandingPage;
