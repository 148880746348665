import Modal from "./Modal";
import { CloseIconCircle } from "../../images/icons";

function ExplainerModal({ openModal, setOpenModal }) {
  return (
    <Modal open={openModal} setOpen={setOpenModal} maxWidth={400}>
      <div className="flex items-center justify-between border-b border-iron pb-4">
        <p>How did we get these numbers?</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIconCircle />
        </div>
      </div>
      <div className="pt-4">
        <p>
          Here at Super Fierce, we know you’re a bunch of smart AF women, so we
          ain’t gonna treat ya like robots. We know a few of you will be lovin’
          some travelling around the globe and others wanna be driving all
          around in a nice car. Some wanna kick back and Netflix and chill.
          Everyone’s got diff goals. Before Blingo, the best tools for
          predicting what $$ you’ll need to fund your life were done by men in
          suits and treats everyone the same. Not ok and super boring. Ew. With
          our calculator, we use well researched assumptions on the costs of
          different lifestyle choices. This means that with our eight questions
          on the Blingo calculator and four levels, this gives us 65,536
          individual budgets. Heck. These can range from $891 per week to $2,632
          per week! Want to take a deeper dive, take a look at more info{" "}
          <a
            href="https://superfierce.com.au/disclosure/"
            className="styled_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            here.
          </a>
        </p>
      </div>
    </Modal>
  );
}

export default ExplainerModal;
