import React from "react";
import SingleScreen from "../components/shared/Layouts/SingleScreen";
import { useNavigate } from "react-router-dom";

function Preload() {
  const navigate = useNavigate();

  return (
    <SingleScreen
      onClick={() => {
        navigate("/yourpersonality");
      }}
      buttonText="SHOW ME!"
      backgroundColor="cinnabar"
    >
      <div
        className="text-5xl lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center justify-center "
        style={{ fontWeight: 500, lineHeight: "4rem" }}
      >
        <div className="relative">
          <p className="w-3/4 mx-auto text-white">
            And you're done!
          </p>
          <p className="text-3xl w-3/4 mx-auto mt-4">Your dream life looks damn fine. Ready to see how much you’ll need for it?</p>
        </div>
      </div>
    </SingleScreen>
  );
}

export default Preload;
