(function (root, factory) {
    'use strict';
    // https://github.com/umdjs/umd/blob/master/returnExports.js
    if (typeof module === 'object' && module.exports) {
        // Node / CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals (root is window)
        root.InputHelper = factory(root.jQuery, root);
    }
}(this, function ($, root) {
    'use strict';

    // Module Management
    var InputHelper = {};

    RegExp.escape = function(s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };
    
    // Private members
    var Attr = {
        InputFormat: 'input-format'
    };
    var DateSeparator = "/";
    var DaysOfMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var Events = {
        Input: 'input'
    };
    var KeyCodes = {
        ArrowDown: 40,
        ArrowLeft: 37,
        ArrowRight: 39,
        ArrowUp: 38,
        Backspace: 8
    };
    var IgnoreKeyCodes = [
        KeyCodes.ArrowDown,
        KeyCodes.ArrowLeft,
        KeyCodes.ArrowRight,
        KeyCodes.ArrowUp,
        KeyCodes.Backspace
    ];
    var TypeHandler = {
        date: {
            format: function (value) {
                var input = value;
    
                var dateParts = input.split(DateSeparator)
                                     .map(function (val) { return val.replace(/\D/g, ''); })
                                     .filter(function (val, idx, array) {
                                         return val !== '' || idx === array.length - 1;
                                     })
                                     .map(function(val, idx, array) {
                                         return idx < array.length - 1 ? ('00' + val).slice(-2) : val;
                                     });
    
                if (dateParts[0]) dateParts[0] = checkValue(dateParts[0], 31);
                if (dateParts[1]) dateParts[1] = checkValue(dateParts[1], 12);
    
                var output = dateParts.map(function(val, idx) {
                    return val.length === 2 && idx < 2 ? val + DateSeparator : val;
                });
    
                return output.join('')
                             .substr(0, 14);
            },
            handleKeyChange: function (keyCode, rawValue) {
                //if (root.getSelection().toString() !== '')
                  //  return;
        
                if ($.inArray(keyCode, IgnoreKeyCodes) !== -1)
                    return;
        
                return InputHelper.Date.format(rawValue);
            },
            validate: function (rawValue) {
                var dateYear = rawValue.substring(6, 10);
                var dateMonth = parseInt(rawValue.substring(3, 5)) - 1;
                var dateDay = rawValue.substring(0, 2);
                var daysInMonth = DaysOfMonth[dateMonth];
        
                if (dateDay <= 0 || dateDay > daysInMonth)
                    throw new Error('Day of month should not be greater than ' + daysInMonth + '.');
                if (dateMonth < 0 || dateMonth >= DaysOfMonth.length)
                    throw new Error('Month should be between 1 and ' + DaysOfMonth.length + '.');

                return new Date(dateYear, dateMonth, dateDay);
            }
        }
    };

    function checkValue(str, max) {
        if (str.charAt(0) !== '0' || str == '00') {
            var num = parseInt(str);

            if (isNaN(num) || num <= 0 || num > max) num = 1;

            str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
        };

        return str;
    };
    function onDocumentReady() {
        $('input[data-' + Attr.InputFormat + ']').each(function () {
            var $this = $(this);
            var handler = TypeHandler[$this.data(Attr.InputFormat)];

            if(typeof handler !== 'function')
                return;

            $this.prop(Attr.InputFormat, function() { return handler; })
                 .on(Events.Input, element_Input);
        });
    }

    // Event handlers
    function element_Input (event) {
        var $this = $(this);
        var rawValue = $this.val();
        var handler = $this.prop(Attr.InputFormat);

        $this.val(function() {
            return handler(rawValue);
        });        
    }

    // Initialization
    $(onDocumentReady);

    InputHelper.formatDate = TypeHandler.date.format;    
    InputHelper.Date = TypeHandler.date;
    InputHelper.TypeHanders = TypeHandler;
    
    return Object.freeze(InputHelper);
}));

