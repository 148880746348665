import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonFooter from "../CommonFooter";
import CommonHeader from "../CommonHeader";
import ExplainerModal from "../ExplainerModal";
import {
  OutlineFacebookIcon,
  OutlineInstagramIcon,
  OutlineLinkedInIcon,
} from "../../../images/icons";

function SingleScreen({
  children,
  onClick,
  buttonText,
  backgroundColor = "cinnabar",
  buttonDisabled = false,
  type,
  stepNumber,
  setStepNumber,
  redirectPath,
  showFooterInMobile = true,
  footerText,
  absoluteButton = false,
  backPath,
  overflowHidden = false,
}) {
  const navigate = useNavigate();
  const [openExplainerModal, setOpenExplainerModal] = React.useState(false);

  return (
    <div
      className={`bg-${backgroundColor} h-screen ${
        overflowHidden ? "overflow-hidden" : "overflow-auto"
      }`}
    >
      <div className="container mx-auto h-full flex flex-col">
        <CommonHeader
          type={type}
          navigateBack={() => {
            if (backPath) {
              navigate(backPath);
            } else {
              if (type === "progress") {
                if (stepNumber > 1) {
                  setStepNumber(stepNumber - 1);
                }
              } else {
                navigate(-1);
              }
            }
          }}
        />
        <div className="h-full  flex-initial flex flex-col justify-between">
          {children}
          {onClick &&
            (redirectPath ? (
              <Link className="mx-auto w-full lg:w-max" to={redirectPath}>
                <button
                  disabled={buttonDisabled}
                  className={`lg:w-max lg:px-36 lg:mx-auto w-full my-6 ${
                    buttonDisabled
                      ? "bg-autofill py-2 font-bold uppercase text-white"
                      : "black-button "
                  }`}
                  onClick={onClick}
                >
                  {buttonText}
                </button>
              </Link>
            ) : (
              <button
                disabled={buttonDisabled}
                className={`lg:w-max lg:px-36 lg:mx-auto w-full my-6 ${
                  buttonDisabled
                    ? "bg-autofill py-2 font-bold uppercase text-white"
                    : "black-button "
                }`}
                onClick={onClick}
              >
                {buttonText}
              </button>
            ))}
        </div>
        <div className={`lg:block ${showFooterInMobile ? "" : "hidden"}`}>
          <div className="container mx-auto py-10 flex items-center justify-between">
            <p 
              onClick={() => {
                setOpenExplainerModal(true);
              }}
              className="text-xs cursor-pointer underline">{footerText}</p>
            <div className=" items-center gap-3 hidden lg:flex">
              <button>
                <OutlineInstagramIcon />
              </button>
              <button>
                <OutlineFacebookIcon />
              </button>
              <button>
                <OutlineLinkedInIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ExplainerModal
        openModal={openExplainerModal}
        setOpenModal={setOpenExplainerModal}
      />
    </div>
  );
}

export default SingleScreen;
