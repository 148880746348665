import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlockScrollBanner from "../components/Blocks/BlockScrollBanner";
import Footer from "../components/Footer";
import { personas } from "../constants/personas";
import Header from "../components/Header";
import FutureLooks from "../components/ShareResults/FutureLooks";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ShareResults() {
  const query = useQuery();
  const navigate = useNavigate();
  const name = query.get("name");
  const personaIndex = query.get("personaIndex");

  React.useEffect(() => {
    if (!name || !personaIndex) {
      navigate("/");
      return;
    }
  }, []);

  const persona = personas.find((item) => item.personaIndex === +personaIndex);

  return (
    <div className="relative diamond-cursor">
      <Header hideOnMobile={true} />
      <img
        src={persona?.image?.src}
        alt={"persona-img"}
        className="lg:hidden block w-full"
      />
      <section className="bg-cinnabar">
        <div className="container mx-auto py-10 lg:flex items-center justify-between">
          <div className="lg:max-w-lg">
            <div className="pb-10">
              <p className="text-lg text-white text-center mb-4 lg:text-left lg:text-4xl lg:text-shark">
                Your friend, {name} is...
              </p>
              <h1 className="text-5xl font-bold italic text-center w-1/2 mx-auto lg:text-6xl lg:text-left lg:w-full">
                The <span className="text-white">{persona?.name}</span>
              </h1>
            </div>
            <div className="p-4 bg-white border border-shark">
              <p className="text-center lg:text-left">{persona?.description}</p>
            </div>
          </div>
          <div className="border border-white p-2  rotate-3 hidden lg:block">
            <img
              src={persona?.image?.src}
              alt={"persona-img"}
              className="hidden lg:block max-w-md"
            />
          </div>
        </div>
      </section>
      <CTA />
      <Footer />
    </div>
  );
}

const CTA = () => {
  return (
    <section className="bg-cinnabar py-10">
      <div className="container mx-auto ">
        <div className="bg-white p-4 border border-shark lg:max-w-lg lg:mx-auto">
          <h2 className="text-3xl text-center">
            What will{" "}
            <span className="uppercase text-cinnabar italic font-bold">
              YOUR
            </span>{" "}
            GranSta life be like?
          </h2>
          <p className="mt-6 text-center text-sm">
            Ever wanted to know what your life will be like when you grow old?
            Play Blingo to manifest your Gransta life and make your dreams a
            reality.
          </p>
          <div className="mt-6 flex flex-col gap-4">
            <Link to="/playblingo">
              <button className="black-button w-full py-2">
                💎 Play Blingo 💎
              </button>
            </Link>
            <Link to="/getyourreport">
              <button className="white-button w-full py-2">
              Get My Free Report
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShareResults;
