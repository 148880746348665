import React from "react";
import Footer from "../Footer";
import CommonHeader from "../shared/CommonHeader";
import reportSuccessImg from "../../images/report-success-img.png";
import { Link } from "react-router-dom";

function SuccessScreen({ setScreenNumber, screenNumber }) {
  return (
    <div className="bg-cinnabar">
      <CommonHeader
        navigateBack={() => {
          setScreenNumber(screenNumber - 1);
        }}
      />
      <div className="container mx-auto">
        <img
          src={reportSuccessImg}
          alt={reportSuccessImg}
          width="285"
          height="303"
          className="mx-auto"
        />
        <div className="bg-white p-4  mb-10  lg:max-w-md mx-auto">
          <h2 className="text-3xl text-center">
            <span className="text-cinnabar italic font-bold">Congrats!</span>{" "}
            We've got everythang
          </h2>
          <p className="text-sm text-center mt-6">
            INBOX PARTY! Our team are running your report. It can take up to 48 hours,
            so hang tight. It’s worth the wait.
          </p>
          <Link to="/">
            <button className="white-button w-full py-2 mt-8">
              Take me back home, baby
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SuccessScreen;
