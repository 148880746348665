import React from "react";
import { getCookie, setCookie } from "../../utils/cookies";
import Footer from "../Footer";
import CommonHeader from "../shared/CommonHeader";
import FirstReportStep from "./FirstReportStep";
import SecondReportStep from "./SecondReportStep";
import ThirdReportStep from "./ThirdReportStep";
import { isEmpty } from "lodash";
import { getSalaryRange } from "../../utils/report";
import { axiosInstance } from "../../utils/axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { validate } from "react-email-validator";
import { Controls } from "@lottiefiles/react-lottie-player";

const appSettings = window.appSettings;

const ReportForm = ({ setScreenNumber, screenNumber }) => {
  const [reportStep, setReportStep] = React.useState(1);
  const [formData, setFormData] = React.useState({
    frm_name: getCookie("name") ? getCookie("name") : "",
    frm_email: getCookie("email") ? getCookie("email") : "",
    sendUpdates: false,
    frm_gender: getCookie("gender") ? getCookie("gender") : 0,
    frm_salary_range: getSalaryRange(getCookie("salary_pa")) || "",
    frm_phone: getCookie("phone") ? getCookie("phone") : "",
    frm_dob: getCookie("dob")
      ? getCookie("dob")
      : "",
    frm_address: getCookie("address") ? getCookie("address") : "",
    frm_city: getCookie("city") ? getCookie("city") : "",
    frm_postcode: getCookie("postcode") ? getCookie("postcode") : "",
    frm_state: getCookie("state") ? getCookie("state") : "",
    frm_country: getCookie("country") ? getCookie("country") : "AU",
    frm_screenshot: null,
  });
  const [error, setError] = React.useState({});
  const navigate = useNavigate();
  const [reportSendResult, setReportSendResult] = React.useState(null);

  const renderReportStep = () => {
    switch (reportStep) {
      case 1:
        return (
          <FirstReportStep
            stepNumber={reportStep}
            setStepNumber={setReportStep}
            formData={formData}
            setFormData={setFormData}
            error={error}
            setError={setError}
          />
        );
      case 2:
        return (
          <SecondReportStep
            stepNumber={reportStep}
            setStepNumber={setReportStep}
            formData={formData}
            setFormData={setFormData}
            error={error}
            setError={setError}
          />
        );
      case 3:
        return (
          <ThirdReportStep
            stepNumber={reportStep}
            setStepNumber={setReportStep}
            formData={formData}
            setFormData={setFormData}
            error={error}
            setError={setError}
          />
        );
      default:
        return null;
    }
  };

  const validateFields = () => {
    let success = true;
    let errorObj = {};
    setError({});
    
    if (reportStep === 1) {
      if (isEmpty(formData.frm_name)) {
        errorObj["frm_name"] = "*This field is required";
        success = false;
      }else if(formData.frm_name.indexOf(" ") < 0){
        errorObj["frm_name"] = "*Must be [first name] [last name]";
        success = false;
      }
      if (isEmpty(formData.frm_email)) {
        errorObj["frm_email"] = "*This field is required";
        success = false;
      }else if(!validate(formData.frm_email)){
        errorObj["frm_email"] = "*This field must be a valid email";
        success = false;
      }
      if (isEmpty(formData.frm_gender)) {
        errorObj["frm_gender"] = "*This field is required";
        success = false;
      }
      if (isEmpty(formData.frm_salary_range)) {
        errorObj["frm_salary_range"] = "*This field is required";
        success = false;
      }
    }
    if (reportStep === 2) {
      
      if (isEmpty(formData.frm_phone)) {
        errorObj["frm_phone"] = "*This field is required";
        success = false;
      } else if(formData.frm_phone.length < 7) {
        errorObj["frm_phone"] = "*This field must be at least 7 digits";
        success = false;
      } else if (!formData.frm_phone.match(/^[0-9 #*\-+().]*$/)) {
        errorObj["frm_phone"] = "*This field must be a valid phone number";
        success = false;
      }
      if (!formData.frm_dob) {
        errorObj["frm_dob"] = "*This field is required";
        success = false;
      }else{
        const currentYear = new Date().getFullYear();
        const birthYear = formData.frm_dob.split("/")[2];
        if(currentYear <= birthYear){
          errorObj["frm_dob"] = "*Year must be in the past";
          success = false;
        }
      }
      if (isEmpty(formData.frm_address)) {
        errorObj["frm_address"] = "*This field is required";
        success = false;
      }
      if (isEmpty(formData.frm_city)) {
        errorObj["frm_city"] = "*This field is required";
        success = false;
      }
      if (isEmpty(formData.frm_postcode)) {
        errorObj["frm_postcode"] = "*This field is required";
        success = false;
      }
      if (isEmpty(formData.frm_state)) {
        errorObj["frm_state"] = "*This field is required";
        success = false;
      }
      if (isEmpty(formData.frm_country)) {
        errorObj["frm_country"] = "*This field is required";
        success = false;
      }
    }
    //Object.keys(errorObj).length === 0
    if(success) {
      setError({});
    }else{
      setError(errorObj);
    }
    return success;
  };

  const handleContinue = () => {
    window.scrollTo(0, 0);
    if (validateFields()) {
      setReportStep(reportStep + 1);
      if (reportStep === 2) {
        handleSubmitFormData();
        setCookie("phone", formData.frm_phone);
        setCookie("dob", formData.frm_dob);
        setCookie("address", formData.frm_address);
        setCookie("city", formData.frm_city);
        setCookie("state", formData.frm_state);
        setCookie("postcode", formData.frm_postcode);
        setCookie("country", formData.frm_country);
        return;
      }
      if (reportStep === 3) {
        handleUploadDocument();
        return;
      }
    }
  };

  const handleSubmitFormData = () => {
    // e.preventDefault();
    let dobArr = formData.frm_dob.split("/");
    let dob = dobArr[2] + "-" + dobArr[1] + "-" + dobArr[0];

    let splitIndex = formData.frm_name.indexOf(" ");
    let firstName = formData.frm_name.substring(0, splitIndex).trim();
    let lastName = formData.frm_name.substring(splitIndex).trim();

    axiosInstance
      .post(
        appSettings.fierceAppUrl + "/api/internal/customer/enquire",
        {
          dateOfBirth: dob,
          email: formData.frm_email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: formData.frm_phone,
          salaryRange: formData.frm_salary,
          streetAddress: {
            city: formData.frm_city,
            country: formData.frm_country,
            postcode: formData.frm_postcode,
            state: formData.frm_state,
            street: formData.frm_address,
          },
          gender: formData.frm_gender,
          dealMeta: {
            enquiry_form_type: "blingo"
          }
        },
        { timeout: 10000 }
      )
      .then((res) => {
        // console.log(res);
        // console.log(res.headers);
        window.dataLayer.push({ event: 'report_consent', eventProps: {}});
        setReportSendResult(res);
        setReportStep(3);
        console.log("customer/enquire success");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleUploadDocument = () => {
    if (reportSendResult.data === null) {
      console.log("customer id not ready");
      return;
    }
    const endpoint = appSettings.fierceAppUrl + `/api/internal/customer/${reportSendResult.data.id}/document `;
    const uploadToken = reportSendResult.headers["upload-token"];
    const staticFormData = new FormData();
    const docFile = formData.frm_screenshot;
    if(formData.frm_screenshot){
      staticFormData.append("document", docFile, docFile.name);
      staticFormData.append("uploadToken", uploadToken);
      axiosInstance
        .post(endpoint, staticFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          window.dataLayer.push({ event: 'super_statement_upload', eventProps: {}});
          console.log("Successful!");
        })
        .catch((e) => {
          console.log(e);
        });
    }
    setScreenNumber(2);
  };

  return (
    <div className="bg-cinnabar">
      <CommonHeader
        backgroundColor="cinnabar"
        navigateBack={() => {
          setScreenNumber(screenNumber - 1);
        }}
      />
      <div className="container mx-auto pb-6">
        <form
          className="p-4 bg-white lg:max-w-xl lg:mx-auto lg:p-10"
          // onSubmit={handleSubmit}
        >
          {renderReportStep()}
          <div className="flex flex-col gap-3 py-4 bg-white">
            <button
              type="button"
              className="black-button w-full py-2 disabled:bg-autofill disabled:border-autofill disabled:text-white"
              onClick={handleContinue}
              // disabled={reportStep === 3 && !formData.frm_screenshot}
            >
              {reportStep == 3 ? 
                "Submit" :
                "Continue"
              }              
            </button>
            <button
              type="button"
              className="white-button w-full py-2  disabled:bg-iron disabled:text-shark disabled:border-iron"
              onClick={() => {
                if (reportStep === 1) {
                  navigate(-1);
                  return;
                }
                setReportStep(reportStep - 1);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ReportForm;
