import React from "react";
import { LocationIcon } from "../images/icons";
import footerLogo from "../images/mockImages/footer-logo.png";
import { Link } from "react-router-dom";

const footerMenus = [
  {
    name: "Contact Us",
    href: "https://superfierce.com.au/contact-us/",
  },
  {
    name: "Privacy",
    href: "https://superfierce.com.au/privacy-policy/",
  },
  {
    name: "Terms",
    href: "https://superfierce.com.au/terms/",
  },
  {
    name: "Financial Services Guide",
    href: "https://superfierce.com.au/financial-services-guide/",
  },
  {
    name: "Disclosures",
    href: "https://superfierce.com.au/disclosure/",
  },
];

const longText = `All financial services are provided by Super Fierce Pty Ltd ABN 22 632 423 575 AFSL No. 534567.

Any financial product advice on this website is general in nature. It does not take into account your needs, financial situation or objectives. Before acting on the advice, you should consider whether it is appropriate to you in light of your needs, financial situation and objectives. Also, past performance is not a reliable indicator of future performance.

You should read the full Terms of Use before utilising Super Fierce services. You should also read the Disclosures and Calculator Assumptions, and the relevant fund’s PDS before making a decision to consolidate or switch your super.

Information about you collected on this website is handled in line with our Privacy Policy.

Any frames, links or other references to other websites, persons or information are produced solely for convenience. Those references are not an endorsement of those parties or their products or their services. Super Fierce does not warrant the accuracy or suitability of any information contained in this or any other website.

We humbly acknowledge the Traditional Owners of the unceded land on which we live and work. We stand in solidarity with Aboriginal and Torres Strait Islander women and First Nations women the world over in their fight for self-determination, recognition and justice.`;

const shortText = `
We humbly acknowledge the Traditional Owners of the unceded land on which we live and work. We stand in solidarity with Aboriginal and Torres Strait Islander women and First Nations women the world over in their fight for self-determination, recognition and justice.`;

const initialStyle = "bg-shark py-10";

function Footer({delayFooter}) {
  
  delayFooter = delayFooter || false;
  const [footerStyle, setFooterStyle] = React.useState(initialStyle + (delayFooter ? " hidden" : ""));

  // console.log("footerStyle: " + footerStyle);
  // console.log("delayFooter: " + delayFooter);

  React.useEffect(() => {
    if(delayFooter){
      setTimeout(() => {
        console.log("load delayed footer")
        setFooterStyle("bg-shark py-10");
      }, 750);
    }
  },[]);

  return (
    <section className={footerStyle}>
      <div className="container mx-auto">
        <div className="border-b-2 border-autofill pb-10">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div>
              <img width={184} height={43} src={footerLogo} alt="footer-logo" />
            </div>
            <Link to="/getyourreport">
              <button
                className="white-button w-full lg:w-max bg-white mt-8 lg:px-10 lg:py-3"
                style={{ border: "1px solid white" }}
              >
                GET G’OLD
              </button>
            </Link>
          </div>
          <div className="text-white flex flex-col gap-8 mt-8 lg:hidden">
            {footerMenus.map((menu, index) => (
              <div key={index}>{menu.name}</div>
            ))}
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-8 text-xs leading-2">
            <div className="text-autofill mt-8 lg:w-1/2">{longText}</div>
            <div className="text-white mt-4 lg:w-1/2 lg:flex lg:items-end">
              {shortText}
            </div>
          </div>
        </div>
        <div className="text-white pt-10 lg:flex lg:items-center">
          <div className="lg:flex lg:w-1/2 lg:gap-10 lg:items-center">
            <a href="mailto:hello@superfierce.com.au">
              <p className="text-sm mb-6 lg:mb-0">hello@superfierce.com.au</p>
            </a>
            <a
              href="https://www.google.com/maps/place/Coolum+Beach+QLD"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 text-sm mb-6 lg:mb-0"
            >
              <LocationIcon />
              <p>Coolum Beach, Australia</p>
            </a>
            <p className="text-sm lg:hidden">Copyright 2022 Super Fierce </p>
          </div>
          <div className="lg:flex lg:w-1/2 lg:justify-between text-sm hidden lg:items-center">
            {footerMenus.map((menu, index) => (
              <a key={index} href={menu.href} target="_blank" rel="noreferrer">
                {menu.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
