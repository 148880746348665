import React from "react";
import StepCounter from "../shared/StepCounter";
import DatePicker from "react-datepicker";

import * as inputHelper from "../../utils/input-helper.js";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


function SecondReportStep({
  stepNumber,
  setStepNumber,
  formData,
  setFormData,
  error,
  setError,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectDate = (e) => {
    // console.log(date);
    // setFormData((prevState) => ({ ...prevState, frm_dob: date }));
    //const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    const newDate = inputHelper.Date.handleKeyChange(22, e.target.value)
    setFormData((prevState) => ({ ...prevState, frm_dob: newDate }));
  };

  return (
    <div>
      <h2 className="text-3xl mb-6 lg:text-4xl lg:w-3/4 ">
        Get your free{" "}
        <span className="text-cinnabar font-bold italic">
        Super Savings Report
        </span>
      </h2>
      <p className="text-sm mb-6">We need some info first...</p>
      <div className="mb-6">
        <StepCounter
          stepTotal={3}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          color="cinnabar"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_phone" className="font-bold text-sm">
            Mobile Number
          </label>
          <input
            id="frm_phone"
            name="frm_phone"
            type="tel" 
            className="border border-iron p-2 outline-0"
            placeholder="Enter phone number"
            value={formData.frm_phone}
            onChange={handleInputChange}
          />
          {error.frm_phone && (
            <p className="text-cinnabar text-sm">{error.frm_phone}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_dob" className="font-bold text-sm">
            Date of birth
          </label>
          <input
            id="frm_dob"
            type="text"
            name="frm_dob"
            className="border border-iron p-2 outline-0 w-full"
            placeholder="Enter date of Birth"
            value={formData.frm_dob}
            onChange={handleSelectDate}
            // dateFormat="dd/MM/yyyy"
          />
          {error.frm_dob && (
            <p className="text-cinnabar text-sm">{error.frm_dob}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-sm" htmlFor="frm_address">
            Address
          </label>
          <input
            id="frm_address"
            name="frm_address"
            className="border border-iron p-2 outline-0"
            placeholder="Enter address"
            value={formData.frm_address}
            onChange={handleInputChange}
          />
          {error.frm_address && (
            <p className="text-cinnabar text-sm">{error.frm_address}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-sm" htmlFor="frm_city">
            City
          </label>
          <input
            id="frm_city"
            name="frm_city"
            className="border border-iron p-2 outline-0"
            placeholder="Enter city"
            value={formData.frm_city}
            onChange={handleInputChange}
          />
          {error.frm_city && (
            <p className="text-cinnabar text-sm">{error.frm_city}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-sm" htmlFor="frm_state">
            State
          </label>
          <input
            id="frm_state"
            name="frm_state"
            className="border border-iron p-2 outline-0"
            placeholder="Enter state"
            value={formData.frm_state}
            onChange={handleInputChange}
          />
          {error.frm_state && (
            <p className="text-cinnabar text-sm">{error.frm_state}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-sm" htmlFor="frm_postcode">
            Postcode
          </label>
          <input
            id="frm_postcode"
            name="frm_postcode"
            className="border border-iron p-2 outline-0"
            placeholder="Enter postcode"
            value={formData.frm_postcode}
            onChange={handleInputChange}
          />
          {error.frm_postcode && (
            <p className="text-cinnabar text-sm">{error.frm_postcode}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecondReportStep;
