import Modal from "./Modal";
import { CloseIconCircle } from "../../images/icons";
import myGovImg from "../../images/my_gov.png";
import superStatementImg from "../../images/super_statement.png";

function FindDeetsModal({ openModal, setOpenModal }) {
  return (
    <Modal open={openModal} setOpen={setOpenModal} maxWidth={500}>
      <div className="flex items-center justify-between border-b border-iron pb-4">
        <p>How do I find my super deets?</p>
        <div
          className="cursor-pointer"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseIconCircle />
        </div>
      </div>
      <div className="pt-4">
        <h4 className="pt-2">Option A: </h4>
        <h4 className="pt-2">Get your most recent statement from your super fund.</h4>
        <p className="pt-3 pb-4">You'll find this in your email mailbox or your super fund's online member portal.</p>
        <img
          src={superStatementImg}
          alt="Super Statement"
          className="block md:mx-auto"
        />
      </div>
      <h4 className="text-center">or</h4>
      <div className="pt-4">
        <h4 className="pt-2">Option B: </h4>
        <h4 className="pt-2">Get your most recent statement through MyGov.</h4>
        <p className="pt-3 pb-4"><a className="link" href="https://www.youtube.com/watch?v=A4WQUlYEcd0&feature=youtu.be" target="_blank">Click here for instructional video</a></p>
        <img
          src={myGovImg}
          alt="My Gov"
          className="block md:mx-auto"
        />
      </div>
    </Modal>
  );
}

export default FindDeetsModal;
