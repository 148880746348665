import heroLogos from "../images/mockImages/heroLogos.png";
import heroBg from "../images/mockImages/herobg.png";
import heroTitleImg from "../images/mockImages/heroTitleImg.png";
import heroMobileImg from "../images/mockImages/hero-mobile-img.png";
import textMedia1Img from "../images/mockImages/text-media-1.png";
import heroDesktopBg from "../images/mockImages/hero-desktop-bg.png";
import heroDesktopImg from "../images/mockImages/hero-desktop-img.png";
import InstagramIcon from "../images/mockImages/instagramIcon.svg";
import mockBffImg from "../images/mockImages/mock-bff.png";

const landingPageData = {
  blocks: [
    {
      identifier: "main_banners",
      name: "blockMarquee",
      startIndex: 1,
      marqueeImages: [
        {
          "id": 1,
          "name": "Retire a millionaire",
          "url": "/getyourreport",
          "image": {
              "data": {
                  "caption": "retire a millionaire",
                  "url": "https://sf-strapi-staging.azureedge.net/strapi-uploads/assets/Screen_Shot_2022_09_07_at_9_53_04_am_a1a5e3fcff.png"
              }
          },
          "mobile_image": {
            "data": {
                "caption": "retire a millionaire",
                "url": "https://sf-strapi-staging.azureedge.net/strapi-uploads/assets/Screen_Shot_2022_09_07_at_9_53_04_am_a1a5e3fcff.png"
            }
        }
      },
      {
          "id": 2,
          "name": "No time, no problem",
          "url": "/getyourreport",
          "image": {
              "data": {
                  "caption": "no time, no problem",
                  "url": "https://sf-strapi-staging.azureedge.net/strapi-uploads/assets/Screen_Shot_2022_09_07_at_9_55_51_am_553ea87ee2.png"
              }
          },
          "mobile_image": {
            "data": {
                "caption": "retire a millionaire",
                "url": "https://sf-strapi-staging.azureedge.net/strapi-uploads/assets/Screen_Shot_2022_09_07_at_9_53_04_am_a1a5e3fcff.png"
            }
          }
      }
      ],
    },
    {
      identifier: "how_it_work",
      name: "blockHowItWorks",
      hiwSteps: [
        {
          title: "You deserve the same lifestyle when you’re older - or better",
          content:
            "But will you have enough dough to live like a total Gransta... or will you be counting pennies?",
        },
        {
          title: "Sorting your dream life is easy",
          content: "We gotchu. The team at Super Fierce has crunched the numbers on every superannuation fund, so you don’t have to, and used decades of financial services expertise to create...",
        },
        {
          title: "YOUR FREE SUPER SAVINGS REPORT",
          content:
            "This baby is the haute couture of financial advice, tailored to your circumstances and needs. It’s valued at $3,000 but we’re giving it to you for FREE, cuz every Aussie deserves to retire on their own terms, with the lifestyle they want",
        },
        {
          title: "How to get your report",
          content:
            "All you need is your contact deets, a handy snap of your latest super statement or balance, and in less than 10mins you’ll be on your way to dat Gransta life. Your future self will love you for it.",
        },
        {
          title: "Start growing G’OLD - not old!",
          content:
            "Once you’ve got your report, we’ll even help you switch super funds, so you can get back to doing more fun things.",
        }
      ],
    },
    {
      identifier: "text_media",
      name: "blockTextMediaLeft",
      title: "As Seen On...",
      content: `Not a bank. Not a super fund. We're financial advice superheroes helping Aussies to retire with $125,000 more on average. We analyse every super fund in the market
      and show you how much you could save. Free, independent and unique - just like you!`,
      buttonBlack: {
        buttonText: "Get My Free Report",
        buttonLink: window.location.origin + "/getyourreport",
      },
      newWindow: true,
      video: {
        id: "wtu24r26A_M",
        url: "https://youtu.be/wtu24r26A_M",
      },
    },
    {
      identifier: "text_media",
      name: "blockTextVideoPink",
      title: "What's Gransta? Growing G'OLD - not Old!",
      content: `You're gonna want the same lifestyle (if not better) when you retire, so lets make it rain gold!
      
      Get your free super savings report and ride off into the sunset like a KWEEN`,
      buttonBlack: {
        buttonText: "Get My Free Report",
        buttonLink: window.location.origin + "/getyourreport",
      },
      newWindow: true,
      video: {
        id: "ZLB8MkZqU1o",
        url: "https://www.youtube.com/watch?v=ZLB8MkZqU1o",
      },
    },
    {
      identifier: "text_media",
      name: "blockTextMedia",
      title: "Not sure how much $$ you need to retire like a Gransta?",
      content: `Play Blingo and we'll tell you how much your dream life will cost, and what you can do to fund it`,
      buttonBlack: {
        buttonText: "💎 Play Blingo 💎",
      },
      image: {
        src: textMedia1Img,
        alt: "image-text-media",
      },
    },
    {
      identifier: "faqs",
      name: "blockFAQs",
      faqTitle: "Frequently Asked Questions",
      faqs: [
        
        {
          question: "What is Super Fierce?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          question: "How do you know so much about Super?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          question: "Why should I care about Super?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          question: "Are my deets safe with you?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          question: "Wait, so what’s the point to Blingo?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        {
          question: "How do I play Blingo?",
          answer:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        }
      ],
    },
    {
      identifier: "instagram_feed",
      name: "blockCarousel",
      carouselTitle: "Let’s get glam on the ‘gram",
      carouselButton: {
        buttonText: "@beSuperFierce",
        icon: InstagramIcon,
      },
      carouselImages: [
        {
          src: "",
          alt: "carousel-1",
          width: 202,
          height: 194,
        },
        {
          src: "",
          alt: "carousel-2",
          width: 202,
          height: 194,
        },
        {
          src: "",
          alt: "carousel-3",
          width: 202,
          height: 194,
        },
        {
          src: "",
          alt: "carousel-4",
          width: 202,
          height: 194,
        },
      ],
    },
    
    {
      identifier: "bff",
      name: "blockBff.DEP",
      bffTitle: "Our BFF's and their GranSta offers",
      bffs: [
        {
          image: {
            src: mockBffImg,
            alt: "mockBffImg",
          },
        },
        {
          image: {
            src: mockBffImg,
            alt: "mockBffImg",
          },
        },
        {
          image: {
            src: mockBffImg,
            alt: "mockBffImg",
          },
        },
      ],
    },
  ],
};

export { landingPageData };
