import React from "react";
import champagneFill from "../../images/champagneFill.png";
import EmptyGlass from "../../images/emptyGlass.png";

function ChampagneGlass({ animate = false, full = false }) {
  return (
    <div className="relative w-max mx-auto" style={{ zIndex: 2 }}>
      <img src={EmptyGlass} width={219} height={408} alt="EmptyGlass" />
      {/* <img
          src={champagneFill}
          alt="champagneFill"
          className="champaign-fill"
        /> */}
      <div>
        <div className="champagne-fill-wrapper relative blur-champagne-bg">
          <img
            src={champagneFill}
            alt="champagneFill"
            className={`absolute ${
              animate === true ? "champagne-animate" : "champagne-fill "
            }`}
            style={full ? { top: 0 } : {}}
          />
        </div>
        <p
          className={`w-1/2 ${
            animate === true ? "animate-champagne-text" : "champagne-text"
          }`}
          style={full ? { top: 0 } : {}}
        ></p>
      </div>
    </div>
  );
}

export default ChampagneGlass;
