import React from "react";
import { useNavigate } from "react-router-dom";
import CalculationFirstStep from "../../components/CalculationCost/CalculationFirstStep";
import CalculationResult from "../../components/CalculationCost/CalculationResult";
import { getCookie, setCookie } from "../../utils/cookies";
import { calculateActualSuper } from "../../utils/superCalc";

function Calculation() {
  const [stepNumber, setStepNumber] = React.useState(1);
  const [actualSuper, setActualSuper] = React.useState(0);

  const [yearBorn, setYearBorn] = React.useState(0);
  const [salaryPa, setSalaryPa] = React.useState(0);
  const [currentBalance, setCurrentBalance] = React.useState(0);

  const [targetSuper, setTargetSuper] = React.useState(0);
  

  const navigate = useNavigate();

  // let yearBorn = getCookie("year_born");
  // let salaryPa = getCookie("salary_pa");
  // let currentBalance = getCookie("super_balance");

  React.useEffect(() => {
    async function fetchData() {
      
      // let targetSuper = getCookie("targetSuper")
      // let yearBorn = getCookie("year_born")
      // let salaryPa = getCookie("salary_pa")
      // let currentBalance = getCookie("super_balance")

      let _targetSuper = getCookie("targetSuper")
      let _year_born = getCookie("year_born")
      let _salary_pa = getCookie("salary_pa")
      let _super_balance = getCookie("super_balance")

      // console.log("_targetSuper: " + _targetSuper)
      // console.log("yearBorn: " + _year_born)
      // console.log("salaryPa: " + _salary_pa)
      // console.log("currentBalance: " + _super_balance)

      setTargetSuper(_targetSuper);
      setYearBorn(_year_born);
      setSalaryPa(_salary_pa);
      setCurrentBalance(_super_balance);

      // console.log("targetSuper: " + targetSuper)
      // console.log("yearBorn: " + yearBorn)
      // console.log("salaryPa: " + salaryPa)
      // console.log("currentBalance: " + currentBalance)


      if (!_targetSuper || !_salary_pa || !_super_balance || !_year_born) {
        navigate("/");
        return;
      }

      const actualSuperRes = await calculateActualSuper(
        _year_born,
        _salary_pa,
        _super_balance
      );

      setCookie("actualSuper", actualSuperRes.actualSuper, 7);

      setActualSuper(actualSuperRes.actualSuper);
    }
    setTimeout(fetchData, 1000);
  }, [yearBorn, salaryPa, currentBalance]);

  // const targetSuper = getCookie("targetSuper");

  const renderCalculationStep = () => {
    switch (stepNumber) {
      case 1:
        return (
          <CalculationFirstStep
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            actualSuper={actualSuper}
            type={actualSuper - targetSuper > 0 ? "positive" : "negative"}
          />
        );
      case 2:
        return (
          <CalculationResult
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            actualSuper={actualSuper}
            type={actualSuper - targetSuper > 0 ? "positive" : "negative"}
          />
        );
      default:
        return <div>Invalid step</div>;
    }
  };

  return <div className="diamond-cursor">{renderCalculationStep()}</div>;
}

export default Calculation;
