import React from "react";
import { ScrollBannerIcon, SurfingIcon } from "../../images/icons";
import Marquee from "react-fast-marquee";

function BlockScrollBanner({ block }) {
  const { bannerText, style, altBannerText } = block;

  if(altBannerText){

    return (
      <section className="overflow-hidden bg-white">
        <Marquee speed="200" gradient={false}>
          <div className="flex items-center lg:gap-4 gap-1 mx-1 md:mx-4">
            <ScrollBannerIcon />
          </div>
          <div className="text font-bold uppercase" style={{ fontStyle: style }}>
            {bannerText} 
          </div>
          <div className="mx-6">
            <ScrollBannerIcon />
          </div>
          <div className="flex items-center lg:gap-4 gap-1 mx-1 md:mx-4">
            <ScrollBannerIcon />
          </div>
          <div className="text font-bold uppercase" style={{ fontStyle: style }}>
            {altBannerText}
          </div>
          <div className="mx-6">
            <ScrollBannerIcon />
          </div>
        </Marquee>
      </section>
    );

  }else{

    return (
      <section className="overflow-hidden bg-white">
        <Marquee speed="200" style={{minWidth: "max-content"}} gradient={false}>
          <div className="mx-6">
            <ScrollBannerIcon />
          </div>
          <div className="text font-bold uppercase" style={{ fontStyle: style }}>
            {bannerText}
          </div>
          <div className="mx-6">
            <ScrollBannerIcon />
          </div>
        </Marquee>
      </section>
    );
  
  }
}

export default BlockScrollBanner;
