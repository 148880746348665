import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { BackButtonIcon } from "../../images/icons";
import leftSparks from "../shared/left-sparks.json";
import rightSparks from "../shared/right-sparks.json";

SwiperCore.use([Navigation]);

function SwiperNavigation() {
  const swiper = useSwiper();
  
  return (
    <div className="flex items-center justify-between mt-4">
      <div className="cursor-pointer" onClick={() => swiper.slidePrev()}>
        <BackButtonIcon />
      </div>
      <div onClick={() => swiper.slideNext()}>
        <div className="transform rotate-180 cursor-pointer">
          <BackButtonIcon />
        </div>
      </div>
    </div>
  );
};

function FutureLooks({ persona }) {
  return (
    <section className="bg-cinnabar py-10">
      <div className="container mx-auto lg:max-w-4xl">
        <p className="text-3xl text-center w-3/4 mx-auto">
          Their future looks{" "}
          <span className="font-bold text-white">glam AF</span>
        </p>
        <div className="mt-10">
          <Swiper
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {persona?.futureLooks.map((look, index) => (
              <SwiperSlide key={index}>
                <LookCard look={look} />
              </SwiperSlide>
            ))}
            <SwiperNavigation/>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

const LookCard = ({ look }) => {
  return (
    <div className="lookcard ">
      <div className="flex justify-center items-center h-full">
        <p className="text-4xl italic text-white uppercase w-3/4 mx-auto text-center font-bold">
          {look.text}
        </p>
      </div>
      <img
        src={look.images[0].src}
        alt={look.images[0].alt}
        width={look.images[0].width}
        height={look.images[0].height}
        className="absolute top-2 -left-10 z-20"
      />
      <img
        src={look.images[1].src}
        alt={look.images[1].alt}
        width={look.images[1].width}
        height={look.images[1].height}
        className="absolute -bottom-10 -right-10 z-20"
      />
      <Player
        autoplay
        loop
        src={leftSparks}
        style={{
          height: "300px",
          width: "300px",
          position: "absolute",
          bottom: -50,
          left: 0,
        }}
      ></Player>
      <Player
        autoplay
        loop
        src={rightSparks}
        style={{
          height: "300px",
          width: "300px",
          position: "absolute",
          top: -150,
          right: 0,
        }}
      ></Player>
    </div>
  );
};

export default FutureLooks;
