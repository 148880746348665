import {
  FacebookOutlineIcon,
  InstagramOutlineIcon,
  LinkIcon,
  MailOutlineIcon,
} from "../images/icons";

export const sharingOptions = [
  {
    text: "Copy results link",
    icon: <LinkIcon />,
    type: "copyUrl",
  },
  {
    text: "Facebook",
    icon: <FacebookOutlineIcon />,
    type: "facebook",
  },
  // {
  //   text: "Instagram",
  //   icon: <InstagramOutlineIcon />,
  // },
  // {
  //   text: "Email",
  //   icon: <MailOutlineIcon />,
  // },
];
