import Login from "../pages/Login";
import RequestReport from "../pages/RequestReport";
import ShareResults from "../pages/ShareResults";
import Play from "../pages/Play";
import Preload from "../pages/Preload";
import Preview from "../pages/Preview";
import Results from "../pages/Results";
import Calculator from "../pages/Calculator";
import Calculation from "../pages/Calculation";
import Report from "../pages/Report";
import LandingPage from "../pages/LandingPage";

const blingoRoutes = [
  {
    path: "shareresults",
    name: "Share Results",
    element: <ShareResults />,
    redirectTo: null,
  },
  {
    path: "requestreport",
    name: "Request Report",
    element: <RequestReport />,
    redirectTo: null,
  },
  { path: "login", name: "Login", element: <Login />, redirectTo: null },
  { path: "playblingo", name: "Play", element: <Play />, redirectTo: "login" },
  {
    path: "omgloading",
    name: "Preload",
    element: <Preload />,
    redirectTo: null,
  },
  {
    path: "haveapeek",
    name: "Preview",
    element: <Preview />,
    redirectTo: null,
  },
  {
    path: "yourpersonality",
    name: "Results",
    element: <Results />,
    redirectTo: null,
  },
  {
    path: "bestlife",
    name: "Calculator",
    element: <Calculator />,
    redirectTo: null,
  },
  {
    path: "calculation",
    name: "Calculation",
    element: <Calculation />,
    redirectTo: null,
  },

  {
    path: "getyourreport",
    name: "Report",
    element: <Report />,
    redirectTo: null,
  },
  { path: "", name: "Landing", element: <LandingPage />, redirectTo: null },
];

export { blingoRoutes };

