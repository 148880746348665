import React from "react";
import LoginMain from "../components/Login/LoginMain";
// import { getCookie } from "../utils/cookies";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const loginComplete = () => {
    navigate("/playblingo");
  };

  // const navigate = useNavigate();
  // React.useEffect(() => {
  //   const user = getCookie("name");
  //   if (user) {
  //     navigate("/");
  //   }
  // });

  return (
    <div>
      {/* Login Section */}
      <LoginMain loginComplete={loginComplete}/>
    </div>
  );
}

export default Login;
