import React from "react";
import { SALARY_RANGE } from "../../utils/report";
import StepCounter from "../shared/StepCounter";

function FirstReportStep({
  stepNumber,
  setStepNumber,
  formData,
  setFormData,
  error,
  setError,
}) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <h2 className="text-3xl mb-6 lg:text-4xl lg:w-3/4 ">
        Get your free{" "}
        <span className="text-cinnabar font-bold italic">
          Super Savings Report
        </span>
      </h2>
      <p className="text-sm mb-6">We need some info first...</p>
      <div className="mb-6">
        <StepCounter
          stepTotal={3}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          color="cinnabar"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_name" className="font-bold text-sm">
            Full name
          </label>
          <input
            id="frm_name"
            name="frm_name"
            className="border border-iron p-2 outline-0"
            placeholder="Enter full name"
            value={formData.frm_name}
            onChange={handleInputChange}
          />
          {error.frm_name && (
            <p className="text-cinnabar text-sm">{error.frm_name}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_email" className="font-bold text-sm">
            Email
          </label>
          <input
            id="frm_email"
            name="frm_email"
            className="border border-iron p-2 outline-0"
            placeholder="Enter Email"
            value={formData.frm_email}
            onChange={handleInputChange}
          />
          {error.frm_email && (
            <p className="text-cinnabar text-sm">{error.frm_email}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_gender" className="font-bold text-sm">
            Gender
          </label>
          <select
            id="frm_gender"
            name="frm_gender"
            className="border border-iron p-2 outline-0"
            value={formData.frm_gender}
            onChange={handleInputChange}
          >
            <option value={''} >{''}</option>
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
          </select>
          {error.frm_gender && (
            <p className="text-cinnabar text-sm">{error.frm_gender}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="frm_salary_range" className="font-bold text-sm">
            Salary Range
          </label>
          <select
            id="frm_salary_range"
            name="frm_salary_range"
            className="border border-iron p-2 outline-0"
            value={formData.frm_salary_range}
            onChange={handleInputChange}
          >
            <option key={'blank'} value={''}></option>
            {Object.keys(SALARY_RANGE).map((key, index) => {
              return (
                <option key={key} value={key}>
                  {SALARY_RANGE[key]}
                </option>
              );
            })}
          </select>
          {error.frm_salary_range && (
            <p className="text-cinnabar text-sm">{error.frm_salary_range}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FirstReportStep;
