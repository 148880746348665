import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Hamburger,
  LeftArrowIcon,
  SuperfierceBlackLogo,
} from "../../images/icons";
import SfLogo from "../../images/sf-logo.png";

function CommonHeader({
  buttonText = "Go back",
  goBack = true,
  navigateBack,
  backgroundColor = null,
  type,
}) {
  const navigate = useNavigate();

  return (
    <section
      className={` py-4 sticky top-0 z-10  ${
        backgroundColor ? `bg-${backgroundColor}` : ""
      }`}
    >
      <div className="container mx-auto flex items-center justify-between">
        {goBack ? (
          <div>
            <button
              className="hidden lg:flex border border-shark p-2 items-center gap-2 hover:bg-white"
              onClick={navigateBack}
            >
              <LeftArrowIcon /> <p>{buttonText}</p>
            </button>
            <button
              className="lg:hidden flex border border-shark p-2 items-center gap-2 hover:bg-white"
              onClick={navigateBack}
            >
              <LeftArrowIcon />
            </button>
          </div>
        ) : (
          <div className="lg:hidden block">
            <Hamburger />
          </div>
        )}
        <div className="lg:hidden block">
          <img width={32} height={32} src={SfLogo} alt="sf-logo" />
        </div>
        <div className="hidden lg:block">
          <a href="https://www.superfierce.com.au" target="_blank">
            <SuperfierceBlackLogo />
          </a>
        </div>
      </div>
    </section>
  );
}

export default CommonHeader;
