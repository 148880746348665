import React from "react";
import NegativeCalculation from "./NegativeCalculation";
import PositiveCalculation from "./PositiveCalculation";

function CalculationResult({ type, stepNumber, setStepNumber }) {
  const renderCalculationResult = () => {
    switch (type) {
      case "positive":
        return (
          <PositiveCalculation
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
          />
        );
      case "negative":
        return (
          <NegativeCalculation
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
          />
        );
      default:
        return <div>Invalid calculation type</div>;
    }
  };

  return <div>{renderCalculationResult()}</div>;
}

export default CalculationResult;
