import React from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import ThirdStep from "./ThirdStep";
import { setCookie } from "../../utils/cookies";

function Game({ item, setStepNumber, numOfQuestions, stepNumber }) {
  const stepCircles = Array.from(Array(numOfQuestions).keys());
  const [gameTab, setGameTab] = React.useState(0);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const renderGameTab = () => {
    switch (gameTab) {
      case 0:

        if(stepNumber == 1){
          setCookie("answerObj","",7);
          window.dataLayer.push({
            event: 'pageview',
            page: {
              url: document.location.origin + document.location.pathname,
              title: "Play Blingo"
            }
          });
        }

        return (
          <FirstStep
            item={item}
            stepCircles={stepCircles}
            setGameTab={setGameTab}
            setStepNumber={setStepNumber}
            stepNumber={stepNumber}
          />
        );
      case 1:
        return (
          <SecondStep
            item={item}
            stepCircles={stepCircles}
            setGameTab={setGameTab}
            setStepNumber={setStepNumber}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            stepNumber={stepNumber}
          />
        );
      case 2:
        //   return (
        //     <ThirdStep
        //       item={item}
        //       stepCircles={stepCircles}
        //       setGameTab={setGameTab}
        //       setStepNumber={setStepNumber}
        //       selectedOption={selectedOption}
        //       stepNumber={stepNumber}
        //     />
        //   );
        // case 3:
        return (
          <FourthStep
            item={item}
            stepCircles={stepCircles}
            setGameTab={setGameTab}
            setStepNumber={setStepNumber}
            selectedOption={selectedOption}
            stepNumber={stepNumber}
            numOfQuestions={numOfQuestions}
          />
        );
      default:
        return null;
    }
  };

  return renderGameTab();
}

export default Game;

