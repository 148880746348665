import React from "react";
import {
  OutlineFacebookIcon,
  OutlineInstagramIcon,
  OutlineLinkedInIcon,
} from "../../images/icons";

function CommonFooter({ footerText = "Copyright 2022 Super Fierce" }) {
  return (
    <div className="container mx-auto py-10 flex items-center justify-between">
      <p className="text-xs underline">{footerText}</p>
      <div className=" items-center gap-3 hidden lg:flex">
        <button>
          <OutlineInstagramIcon />
        </button>
        <button>
          <OutlineFacebookIcon />
        </button>
        <button>
          <OutlineLinkedInIcon />
        </button>
      </div>
    </div>
  );
}

export default CommonFooter;
