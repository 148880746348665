import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { blingoRoutes } from "./constants/routes";

function Navigation() {
  return (
    <Router>
      <div>
        <React.Fragment>
          <Routes>
            {blingoRoutes.map((route, index) => (
              <Route
                key={index}
                path={`/${route.path}`}
                element={route.element}
              />
            ))}
          </Routes>
        </React.Fragment>
      </div>
    </Router>
  );
}

export default Navigation;
