import BabyDarkImg from "../images/mockImages/babydark.png";
import BabyLightImg from "../images/mockImages/babylight.png";

const TWO_OPTIONS = "twoOptions";
const TWO_STEP_INPUT = "twoStepsInput";

const PRIVACY_POLICY = "privacy-policy";
const STEPS_SCREEN = "steps-screen";

export {
  PRIVACY_POLICY,
  STEPS_SCREEN,
  TWO_OPTIONS,
  TWO_STEP_INPUT,
};

export const calculatorSteps = [
  {
    stepNumber: 1,
    componentName: TWO_OPTIONS,
    field: "gender",
    heading: "What gender did you put into the gov website?",
    options: [
      {
        name: "♀ Female",
        value: "female",
      },
      {
        name: "♂ Male",
        value: "male",
      },
    ],
    note: {
      noteHeading: "WE LOVE YOU",
      noteContent:
        "Now, we know that gender isn’t binary. But at the moment the Govt only allows two options. We welcome everyone with hearts, minds and arms wide open! But, for this small moment in time we’ll need you to choose one of these choices....",
    },
  },
  {
    stepNumber: 2,
    componentName: TWO_STEP_INPUT,
    type: "age",
    field: "year_born",
    heading: "What year were you born?",
    input: {
      placeholder: "Enter Birth Year",
    },
    floatIcons: [
      {
        src: BabyDarkImg,
        alt: "BabyDarkImg",
        position: "right",
        width: 46.45,
        height: 46.45,
      },
      {
        src: BabyLightImg,
        alt: "BabyLightImg",
        position: "left",
        width: 46.45,
        height: 46.45,
      },
    ],
    successfullMessage: "Nice! 36 years of awesome.",
  },
  {
    stepNumber: 3,
    componentName: TWO_STEP_INPUT,
    type: "money",
    field: "salary_pa",
    heading: "What's your annual salary?",
    paragraph: "Close enough is good enough! It helps us work out how much you need to save",
    input: {
      placeholder: "Enter Salary",
    },
  },
  {
    stepNumber: 4,
    componentName: TWO_STEP_INPUT,
    type: "money",
    field: "super_balance",
    heading: "So, how much have you saved up for retirement, so far?",
    paragraph: "The more accurate you can be, the more we can help",
    input: {
      placeholder: "Enter Savings",
    },
  },
];
