import React from "react";
import SingleScreen from "../shared/Layouts/SingleScreen";
import ChampagneGlass from "../shared/ChampagneGlass";
import { currencyFormatter } from "../../utils/currencyInput";
import { getCookie } from "../../utils/cookies";
import { useNavigate } from "react-router-dom";
import ExplainerModal from "../shared/ExplainerModal";

const CalculationFirstStep = ({
  stepNumber,
  setStepNumber,
  actualSuper,
  type,
}) => {
  const [tabNumber, setTabNumber] = React.useState(0);
  const [openExplainerModal, setOpenExplainerModal] = React.useState(false);

  const renderTab = () => {
    switch (tabNumber) {
      case 0:
        return (
          <FirstTab
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
          />
        );
      case 1:
        return (
          <SecondTab
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            actualSuper={actualSuper}
            setOpenExplainerModal={setOpenExplainerModal}
            type={type}
          />
        );
      case 2:
        return (
          <ThirdTab
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            actualSuper={actualSuper}
            setOpenExplainerModal={setOpenExplainerModal}
            type={type}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {renderTab()}
      <ExplainerModal
        openModal={openExplainerModal}
        setOpenModal={setOpenExplainerModal}
      />
    </div>
  );
};

const FirstTab = ({ tabNumber, setTabNumber }) => {
  React.useEffect(() => {
    setTimeout(() => {
      setTabNumber(tabNumber + 1);
    }, 2000);
  }, []);

  return (
    <SingleScreen
      showFooterInMobile={false}
      backgroundColor="cinnabar"
      buttonText="CONTINUE"
    >
      <div
        className="text-5xl lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center justify-center "
        style={{ fontWeight: 500, lineHeight: "4rem" }}
      >
        Drum roll please while our calculator runs the 
        <span className="text-5xl  font-bold text-white">numbers…</span>
      </div>
    </SingleScreen>
  );
};

const SecondTab = ({
  tabNumber,
  setTabNumber,
  actualSuper,
  setOpenExplainerModal,
  type,
}) => {
  return (
    <SingleScreen
      showFooterInMobile={false}
      backgroundColor="cinnabar"
      absoluteButton={true}
      overflowHidden={true}
    >
      <div className="lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center mb-10">
        <div className="text-4xl" style={{ lineHeight: "3rem" }}>
          You’re on track to{" "}
          <span className=" text-shark italic" style={{ fontWeight: 500 }}>
            retire
          </span>{" "}
          with
        </div>
        <div>
          <span className="text-7xl italic font-bold text-white">
            {currencyFormatter.format(actualSuper)}
          </span>
        </div>
        <p
          className="text-sm mt-6 underline cursor-pointer"
          onClick={() => {
            setOpenExplainerModal(true);
          }}
        >
          How we worked out your retirement savings projection
        </p>
      </div>

      <div className="h-full relative bottom-0 right-0 w-full flex items-center justify-center">
        <div className="champagne-glass-container">
          <ChampagneGlass animate={type === "positive"} />
        </div>
      </div>
      <button
        className="black-button absolute bottom-20 w-max mx-auto px-20 py-2 z-10 left-0 right-0"
        onClick={() => {
          setTabNumber(tabNumber + 1);
        }}
      >
        CONTINUE
      </button>
    </SingleScreen>
  );
};

const ThirdTab = ({
  tabNumber,
  setTabNumber,
  stepNumber,
  setStepNumber,
  setOpenExplainerModal,
  type,
}) => {
  const targetSuper = getCookie("targetSuper");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!targetSuper) {
      navigate("/");
    }
  }, []);

  return (
    <SingleScreen
      showFooterInMobile={false}
      backgroundColor="cinnabar"
      buttonText="CONTINUE"
      type="progress"
      stepNumber={tabNumber}
      setStepNumber={setTabNumber}
      overflowHidden={true}
    >
      <div className="lg:w-1/2 lg:mx-auto text-center flex-1 flex flex-col items-center  ">
        <div className="lg:mb-20">
          <div className="text-4xl" style={{ lineHeight: "3rem" }}>
            Your <span className="font-bold text-shark italic">dream life</span>{" "}
            will cost....
          </div>
          <div>
            <span
              className="text-7xl italic text-white"
              style={{ fontWeight: 500 }}
              id="increment-money"
            >
              {currencyFormatter.format(targetSuper)}
            </span>
          </div>
          <p
            className="text-sm mt-6 underline cursor-pointer"
            onClick={() => {
              setOpenExplainerModal(true);
            }}
          >
            How we calculated the cost of your dream life
          </p>
        </div>
      </div>
      <div className="h-full relative bottom-0 right-0 w-full flex items-center justify-center">
        <div className="champagne-glass-container">
          <ChampagneGlass full={true} animate={type === "negative"} />
        </div>
      </div>
      <button
        className="black-button absolute bottom-20 w-max mx-auto px-20 py-2 z-10 left-0 right-0"
        onClick={() => {
          setStepNumber(stepNumber + 1);
        }}
      >
        CONTINUE
      </button>
    </SingleScreen>
  );
};

export default CalculationFirstStep;
