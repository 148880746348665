import React from "react";
import { axiosInstance } from "../../utils/axios";
import { getCookie, setCookie } from "../../utils/cookies";
import SingleScreen from "../shared/Layouts/SingleScreen";
import StepCounter from "../shared/StepCounter";

function CalcTwoOptions({ step, stepNumber, setStepNumber, stepTotal }) {
  const [selectedOption, setSelectedOption] = React.useState(null);

  const savedAnswer = getCookie(step.field);
  React.useEffect(() => {
    if (savedAnswer) {
      setSelectedOption(savedAnswer);
    }
  }, []);

  const handleSelectAnswer = () => {
    const sessionId =
      getCookie("sid") === "undefined" ? undefined : getCookie("sid");

    setStepNumber(stepNumber + 1);
    setCookie(step.field, selectedOption, 7);
    //axiosInstance
    //  .post("/add-field", null, {
    //    params: { k: step.field, v: selectedOption, sid: sessionId },
    //  })
    //  .then(function (response) {
    //    if (response.data.sid) {
    //      setCookie("sid", response.data.sid, 7);
    //    }
    //    setStepNumber(stepNumber + 1);
    //    setCookie(step.field, selectedOption, 7);
    //  })
    //  .catch(function (error) {
    //    console.log(error);
    //  });
  };

  return (
    <SingleScreen
      onClick={handleSelectAnswer}
      buttonText="Continue"
      backgroundColor="cream"
      buttonDisabled={selectedOption === null}
      stepNumber={stepNumber}
      setStepNumber={setStepNumber}
      type="normal"
    >
      <div className="flex-1 flex flex-col items-center justify-center">
        <div className="mb-6">
          <StepCounter
            stepNumber={stepNumber}
            stepTotal={stepTotal}
            setStepNumber={setStepNumber}
          />
        </div>
        <h1 className="text-4xl text-center">{step.heading}</h1>
        <div>
          <div className="flex flex-col gap-10 lg:gap-6 mt-8 lg:mt-16">
            {step.options.map((option, index) => (
              <div
                className={`text-5xl lg:text-4xl italic ${
                  selectedOption === option.value ? "opacity-100" : "opacity-50"
                }`}
                tabIndex={index}
                key={index}
                onFocus={() => {
                  setCookie(step.field, option.value, 7);
                  setSelectedOption(option.value);
                }}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="justify-end flex flex-col lg:w-2/3 lg:mx-auto mt-8">
        <div className="border border-shark p-4 text-center">
          <p className="font-bold mb-4">{step.note.noteHeading}</p>
          <p>{step.note.noteContent}</p>
        </div>
      </div>
    </SingleScreen>
  );
}

export default CalcTwoOptions;
