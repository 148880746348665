import React from "react";
import {
  CameraWithFlashIcon,
  ScrollBannerIcon,
  SurfingIcon,
  UnicornIcon,
} from "../../images/icons";
import Marquee from "react-fast-marquee";

function CommonMarquee({ componentList, seperator, textStyle }) {
  return (
    <section className="overflow-hidden bg-white py-2">
      <Marquee speed="200" gradient={false}>
        {componentList.map((component, index) => (
          <MarqueeChild
            key={index}
            component={component}
            textStyle={textStyle}
            seperator={seperator}
          />
        ))}
      </Marquee>
    </section>
  );
}

const MarqueeChild = ({ component, textStyle, seperator }) => {
  return (
    <React.Fragment>
      <div className="flex items-center lg:gap-4 gap-1 mx-1 md:mx-4">
        <img
          className="hidden lg:block"
          width={35}
          height={35}
          src={component.icon}
          alt="banner-icon"
        />
        <div
          className="text font-bold uppercase"
          style={{ fontStyle: textStyle }}
        >
          {component.text}
        </div>
      </div>
      <div className="mx-20 hidden lg:block">{seperator}</div>
    </React.Fragment>
  );
};

export default CommonMarquee;
