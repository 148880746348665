import React from "react";
import {
  calculatorSteps,
  TWO_OPTIONS,
  TWO_STEP_INPUT,
} from "../../constants/calculatorSteps";
import CalcTwoOptions from "./CalcTwoOptions";
import CalcTwoStepInput from "./CalcTwoStepInput";

function CalculatorSteps() {
  const [stepNumber, setStepNumber] = React.useState(1);

  return (
    <div>
      {calculatorSteps.map((step, index) => (
        <CalculatorStep
          key={index}
          step={step}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          stepTotal={calculatorSteps.length}
        />
      ))}
    </div>
  );
}

const CalculatorStep = ({ step, stepNumber, setStepNumber, stepTotal }) => {
  const renderCalcStep = (stepInput) => {
    switch (stepInput.componentName) {
      case TWO_OPTIONS:
        return (
          <CalcTwoOptions
            step={stepInput}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            stepTotal={stepTotal}
          />
        );
      case TWO_STEP_INPUT:
        return (
          <CalcTwoStepInput
            step={stepInput}
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            stepTotal={stepTotal}
          />
        );
      default:
        return <div>Step not found</div>;
    }
  };

  return <div>{stepNumber === step.stepNumber && renderCalcStep(step)}</div>;
};

export default CalculatorSteps;
