import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import ReportForm from "../components/ReportForm";
import SuccessScreen from "../components/ReportForm/SuccessScreen";
import CommonHeader from "../components/shared/CommonHeader";
import reportPreloadImg from "../images/report-preload-img.png";

function Report() {
  const [screenNumber, setScreenNumber] = React.useState(0);

  const renderSteps = () => {
    switch (screenNumber) {
      case 0:

        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: document.location.origin + document.location.pathname,
            title: "Get Your Report"
          }
        });

        return (
          <ReportPreload
            screenNumber={screenNumber}
            setScreenNumber={setScreenNumber}
          />
        );
      case 1:
        return (
          <ReportForm
            screenNumber={screenNumber}
            setScreenNumber={setScreenNumber}
          />
        );
      case 2:
        return (
          <SuccessScreen
            screenNumber={screenNumber}
            setScreenNumber={setScreenNumber}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderSteps()}</div>;
}

const ReportPreload = ({ setScreenNumber }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-cinnabar">
      <CommonHeader
        backgroundColor="cinnabar"
        navigateBack={() => {
          navigate(-1);
        }}
      />
      <div className="container mx-auto py-10 lg:max-w-xl lg:pb-20 ">
        <div className="bg-white p-4  lg:p-10">
          <h3 className="text-3xl mb-6">What’s in this UH-MAZING report?</h3>
          <img
            src={reportPreloadImg}
            alt="reportPreloadImg"
            className="w-full mb-6"
          />
          <ul className="flex flex-col gap-3 font-bold mb-6">
            <li>$ in super today</li>
            <li>$ at retirement if you do nothing</li>
            <li>$ you need for the lifestyle you’ll love</li>
            <li>$ you could save on fees</li>
            <li>Then KAPOW! You'll know how much more you can retire with.</li>
          </ul>
          <p className="mb-6">
            And yes, we know what you’re thinking. This IS financial advice. We’re
             licensed to provide it and we’re super proud of it. Why? Because it’s
             completely independent, unbiased and FREE. We care about the future
             of Aussie women (and, yes, blokes too!) and we want you to live your
             dream retirement.
          </p>
          <div className="flex flex-col gap-3 mb-6">
            <button
              className="black-button w-full py-2"
              onClick={() => {
                setScreenNumber(1);
                window.scrollTo(0, 0);
              }}
            >
              CONTINUE
            </button>
            <button
              className="white-button w-full py-2"
              onClick={() => {
                navigate(-1);
              }}
            >
              BACK
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Report;
